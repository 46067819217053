import React from 'react';
import { IssueBar } from '../IssueBar/IssueBar';
import './style.css';

export const IssueGallery = ({ issuesData = [], party, repImageUrl }) => {
    const groupedIssues = issuesData.reduce((acc, issue) => {
        const key = issue.topic_name || issue.group_name;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(issue);
        return acc;
    }, {});

    return (
        <div className="issue-gallery">
            {Object.entries(groupedIssues).length > 0 ? (
                Object.entries(groupedIssues).map(([groupName, issues], index) => (
                    <IssueBar
                        key={index}
                        head={groupName}
                        issues={issues}
                        party={party}
                        repImageUrl={repImageUrl}
                    />
                ))
            ) : (
                <div className="placeholder-message">No issues available.</div>
            )}
        </div>
    );
};