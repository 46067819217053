import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import QuizCard from '../../components/QuizCard/QuizCard';
import { Banner } from '../../components/Banner/Banner';
import { QuizComponent } from '../../components/QuizComponent/QuizComponent';
import './style.css';



export const Quiz = ({ userEmail }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizDetails, setQuizDetails] = useState({});
  const [quizId, setQuizId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await fetch(`${window.apiUrl}/quizzes`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching quizzes: ${response.statusText}`);
        }

        const data = await response.json();
        setQuizzes(data);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    fetchQuizzes();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlQuizId = searchParams.get('quizId');
    
    if (urlQuizId && urlQuizId !== quizId) {
      setQuizId(urlQuizId);
      const selectedQuizDetails = quizzes.find(quiz => quiz.QuizID === urlQuizId);
      if (selectedQuizDetails) {
        handleQuizSelect(selectedQuizDetails);
      }
    }
  }, [location, quizId, quizzes]);

  const handleQuizSelect = async (quiz) => {
    try {
      const response = await fetch(`${window.apiUrl}/quizquestions?quizId=${quiz.QuizID}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching quiz questions: ${response.statusText}`);
      }

      const quizData = await response.json();
      console.log("Quiz data fetched:", quizData);
      setSelectedQuiz(quizData);
      setQuizDetails(quiz);
      setQuizId(quiz.QuizID);

      // Update URL with quiz ID
      window.history.pushState(null, '', `/quiz?quizId=${quiz.QuizID}`);
    } catch (error) {
      console.error('Error fetching quiz questions:', error);
    }
  };

  const resetQuizSelection = () => {
    setSelectedQuiz(null);
    setQuizDetails({});
    setQuizId(null);
    window.history.pushState(null, '', '/quiz');
  };

  const memoizedQuizComponent = useMemo(() => {
    if (selectedQuiz) {
      const associatedTopic = quizDetails.AssociatedTopic;
      console.log("Rendering QuizComponent with data:", selectedQuiz);

      return (
        <QuizComponent
          key={quizId}
          quizData={selectedQuiz}
          quizDetails={quizDetails}
          associatedTopic={associatedTopic}
          resetQuizSelection={resetQuizSelection}
        />
      );
    }
    return null;
  }, [selectedQuiz, quizDetails, resetQuizSelection, quizId]);

  if (selectedQuiz) {
    return (
      <div className="quiz-page-wrapper">
        {memoizedQuizComponent}
      </div>
    );
  }

  return (
    <div className="quiz-page-wrapper">
      <Banner title="Quiz" />
      <div className="quiz-page-main">
        <div className="title-wrap">
          <div className="title">Take a quiz!</div>
          <div className="text">See below for available quizzes. Check back periodically for updates!</div>
        </div>
        <div className="quizzes-container">
          {quizzes.map(quiz => (
            <QuizCard
              key={quiz.QuizID}
              quiz={quiz}
              onClick={() => handleQuizSelect(quiz)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
