import React, { useState } from "react";
import { Button } from "../Button/Button";
import Select from 'react-select';
import "./style.css";
import Back from "./back.svg";
import Image from "./image.png";

const apiUrl =  'https://votesee.org/api';

const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];

export const Setup2 = ({ nextStep, setUserDetails, userDetails }) => {
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState(null);
    const [zip_code, setZipCode] = useState("");

    const handleNext = async () => {
        if (!state) {
            alert("State is required to proceed.");
            return;
        }

        const updatedUserDetails = {
            email: userDetails.email, // Use the existing email as identifier
            state: state.value,
        };

        // Only add optional fields if they have been filled out
        if (address.trim()) updatedUserDetails.address = address;
        if (city.trim()) updatedUserDetails.city = city;
        if (zip_code.trim()) updatedUserDetails.zip_code = zip_code;

        try {
            // Update user in backend
            const response = await fetch(`${window.apiUrl}/users`, {
                method: 'PATCH',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedUserDetails),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update user in backend: ${errorText}`);
            }

            setUserDetails(prevDetails => ({...prevDetails, ...updatedUserDetails}));
            nextStep();
        } catch (error) {
            console.error("Error updating user:", error);
            alert(`Error updating user: ${error.message}`);
        }
    };

    return (
        <div className="base-signup2">
            <div className="left-section">

                <div className="box">
                    <h1 className="title">Advanced Setup</h1>
                    <p className="desc">Sign Up to Get Started</p>

                    <div className="un">
                        <Select
                            className="input-select"
                            placeholder="Select your state"
                            value={state}
                            onChange={setState}
                            options={states}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: '#F8F8F8',
                                    height: '56px',
   
                                    border: '0px',
                                    fontSize: '18px',
                                    fontWeight: '300',
                                    fontFamily: 'Inter',
                                    color: '#111',
                                    marginLeft: '12px',
                                })}}
                        />
       
                    </div>
                    <div className="un">
                        <input 
                            type="text" 
                            className="input" 
                            placeholder="Enter your city (optional)" 
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                      
                    </div>
                    <div className="un">
                        <input 
                            type="text" 
                            className="input" 
                            placeholder="Enter your zip code (optional)" 
                            value={zip_code}
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                     
                    </div>
                    <div className="un">
                        <input 
                            type="text" 
                            className="input" 
                            placeholder="Enter your street address (optional)" 
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    
                    </div>
                    <div className="disclaimer">
                        With a street address, we will be able to provide you with information on your local senators and representatives. You will be better informed about policy actions that affect you. If you proceed without a street address, we will provide information on all representatives at a regional and state level, including some who represent areas near you. Reminder: the search function is always available for finding all members of Congress.
                    </div>
                    <div className="button-container">
                        <Button className="button" property1="primary" text="Next" onClick={handleNext} />
                    </div>
                    <div className="skip"></div>
                </div>
            </div>
        </div>
    );
};