import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CompareRepsSelection } from '../../components/CompareRepsSelection/CompareRepsSelection';
import { Banner } from '../../components/Banner/Banner';
import './style.css';

export const CompareReps = () => {
    const [showScores, setShowScores] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const handlePopState = () => {
            // Check if there are URL parameters
            const hasParams = new URLSearchParams(location.search).toString() !== '';
            setShowScores(hasParams);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location]);

    useEffect(() => {
        // Set showScores based on URL parameters
        const hasParams = new URLSearchParams(location.search).toString() !== '';
        setShowScores(hasParams);
    }, [location.search]);

    return (
        <div className="compare-reps-page">
            <Banner
                title="Compare Reps"
                description="See how Congressional Representatives have voted on the issues that matter most to you."
            />
            <CompareRepsSelection
                showScores={showScores}
                setShowScores={setShowScores}
                onBack={() => {
                    history.push('/compare-reps');
                }}
            />
        </div>
    );
};

export default CompareReps;
