import React from 'react';
import { IssueBar } from '../IssueBar/IssueBar'; // Adjust the import if necessary
import './style.css';

export const IssueGallery = ({ issuesData = [], party }) => {

    
    return (
        <div className="issue-gallery">
            {issuesData.length > 0 ? (
                issuesData.map((issue, index) => {

                    return (
                        <IssueBar
                            key={index}
                            head={issue.topic_name || issue.group_name || ''}
                            title={<span title={issue.description}>{issue.topic_name || issue.group_name || ''}</span>}
                            data={[{
                                position: issue.average_score || issue.score || 0,
                                imageUrl: issue.imageUrl || '',
                                score: issue.average_score || issue.score || 0,
                                party: issue.party || party || '',
                                rep: issue.rep || '',
                                politician_id: issue.politician_id || ''
                            }]}
                            labels={[issue.p2 || '', issue.p1 || '']} // Restore original order
                            tooltips={[issue.p2_def || '', issue.p1_def || '']} // Restore original order
                            desc={[issue.description || '']}
                        />
                    );
                })
            ) : (
                <div className="placeholder-message">No issues available.</div>
            )}
        </div>
    );
};