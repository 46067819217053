import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import '../../styles/commonChartStyles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const congressControl = {
  'sen': {
    '2005-2006': 'Republican',
    '2007-2008': 'Democrat',
    '2009-2010': 'Democrat',
    '2011-2012': 'Democrat',
    '2013-2014': 'Democrat',
    '2015-2016': 'Republican',
    '2017-2018': 'Republican',
    '2019-2020': 'Republican',
    '2021-2022': 'Democrat',
    '2023-2024': 'Democrat'
  },
  'rep': {
    '2005-2006': 'Republican',
    '2007-2008': 'Democrat',
    '2009-2010': 'Democrat',
    '2011-2012': 'Republican',
    '2013-2014': 'Republican',
    '2015-2016': 'Republican',
    '2017-2018': 'Republican',
    '2019-2020': 'Democrat',
    '2021-2022': 'Democrat',
    '2023-2024': 'Republican'
  }
};

export const PartyChangeOverTime = () => {
  const [data, setData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('All');
  const [chartDataSenate, setChartDataSenate] = useState(null);
  const [chartDataHouse, setChartDataHouse] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: { 
        reverse: true,
        title: {
          display: true,
          text: 'Partisan lean',
          font: {
            size: 16,
            weight: 'bold'
          }
        },
        ticks: {
          font: {
            size: 14
          }
        },
        grid: {
          color: (context) => context.tick.value === 0 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)',
          lineWidth: (context) => context.tick.value === 0 ? 2 : 1,
        },
      },
      y: { 
        title: {
          display: true,
          text: 'Year',
          font: {
            size: 16,
            weight: 'bold'
          }
        },
        ticks: {
          font: {
            size: 14
          }
        },
        stacked: true,
      },
    },
    plugins: {
      legend: { 
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 15,
          font: {
            size: 14
          }
        }
      },
      tooltip: { 
        mode: 'index', 
        intersect: false,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: 'rgba(0, 0, 0, 0.8)',
        bodyColor: 'rgba(0, 0, 0, 0.7)',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            const value = context.raw;
            if (context.dataset.label === 'Democrat Scores') {
              return `Democrat & Independent Scores: ${(50 - value).toFixed(2)}`;
            } else if (context.dataset.label === 'Republican Scores') {
              return `Republican Scores: ${(50 + value).toFixed(2)}`;
            } else if (context.dataset.label === 'Both Parties') {
              return `Both Parties: ${(value + 50).toFixed(2)}`;
            }
            return `${context.dataset.label}: ${value.toFixed(2)}`;
          }
        }
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
  });

  useEffect(() => {
    fetchTopics();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedTopic]);

  const fetchTopics = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/groupdefinitions`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      setTopics(['All', ...response.data.map(group => group.group_name)]);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchData = async () => {
    try {
      console.log('Fetching data for topic:', selectedTopic);
      const responseSenate = await axios.get(`${window.apiUrl}/partyscoresummary`, {
        params: { 
          group_name: selectedTopic,
          type: 'sen'
        },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      const responseHouse = await axios.get(`${window.apiUrl}/partyscoresummary`, {
        params: { 
          group_name: selectedTopic,
          type: 'rep'
        },
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      console.log('API response Senate:', responseSenate.data);
      console.log('API response House:', responseHouse.data);
      setData({senate: responseSenate.data, house: responseHouse.data});
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const processChartData = useCallback((type) => {
    console.log(`Processing chart data for ${type}`);
    const filteredData = data[type] || [];  // Use an empty array if data[type] is undefined
    
    console.log('Filtered data:', filteredData);
    console.log('Selected topic:', selectedTopic);

    const currentYear = new Date().getFullYear();
    const startYear = 2005;
    const yearPairs = [];
    for (let year = currentYear; year >= startYear; year -= 2) {
      yearPairs.push(`${year-1}-${year}`);
    }

    const averagedData = yearPairs.map(yearPair => {
      const [startYear, endYear] = yearPair.split('-').map(Number);
      const yearData = filteredData.filter(d => d.year >= startYear && d.year <= endYear);
      
      const democratScores = yearData.filter(d => d.party === 'Democrat').map(d => parseFloat(d.average_score));
      const republicanScores = yearData.filter(d => d.party === 'Republican').map(d => parseFloat(d.average_score));
      const bothScores = yearData.filter(d => d.party === 'Both').map(d => parseFloat(d.average_score));

      console.log(`Year pair ${yearPair}:`, {
        democratScores,
        republicanScores,
        bothScores
      });

      return {
        yearPair,
        Democrat: democratScores.length ? democratScores.reduce((a, b) => a + b, 0) / democratScores.length : null,
        Republican: republicanScores.length ? republicanScores.reduce((a, b) => a + b, 0) / republicanScores.length : null,
        Both: bothScores.length ? bothScores.reduce((a, b) => a + b, 0) / bothScores.length : null,
        majorityParty: congressControl[type === 'senate' ? 'sen' : 'rep'][yearPair],
      };
    });

    console.log('Averaged data:', averagedData);

    // Calculate the min and max values for the x-axis
    const allValues = averagedData.flatMap(d => [
      d.Democrat ? d.Democrat - 50 : null,
      d.Republican ? d.Republican - 50 : null,
      d.Both ? d.Both - 50 : null
    ]).filter(v => v !== null);

    const minValue = Math.floor(Math.min(...allValues));
    const maxValue = Math.ceil(Math.max(...allValues));

    const getColor = (value, isRepublican) => {
      const baseColor = isRepublican ? [200, 30, 30] : [20, 80, 220]; // More vibrant base colors
      const intensity = Math.min(Math.abs(value) / 20, 1); // Increased sensitivity
      const r = Math.round(baseColor[0] + (255 - baseColor[0]) * (1 - intensity));
      const g = Math.round(baseColor[1] + (255 - baseColor[1]) * (1 - intensity));
      const b = Math.round(baseColor[2] + (255 - baseColor[2]) * (1 - intensity));
      return `rgba(${r}, ${g}, ${b}, 0.85)`; // Slightly increased opacity
    };

    const chartData = {
      labels: yearPairs,
      datasets: [
        {
          label: 'Democrat Scores',
          data: averagedData.map(d => d.Democrat ? Math.max(d.Democrat - 50, 0) : null),
          backgroundColor: averagedData.map(d => d.Democrat ? getColor(Math.max(d.Democrat - 50, 0), false) : 'rgba(0, 0, 0, 0)'),
          borderColor: averagedData.map(d => d.Democrat ? getColor(Math.max(d.Democrat - 50, 0), false).replace('0.7', '1') : 'rgba(0, 0, 0, 0)'),
          borderWidth: 1,
          borderRadius: 4,
          borderSkipped: false,
          barPercentage: 0.8,
          categoryPercentage: 0.9,
          order: 2,
          type: 'bar',
        },
        {
          label: 'Republican Scores',
          data: averagedData.map(d => d.Republican ? Math.min(d.Republican - 50, 0) : null),
          backgroundColor: averagedData.map(d => d.Republican ? getColor(Math.min(d.Republican - 50, 0), true) : 'rgba(0, 0, 0, 0)'),
          borderColor: averagedData.map(d => d.Republican ? getColor(Math.min(d.Republican - 50, 0), true).replace('0.7', '1') : 'rgba(0, 0, 0, 0)'),
          borderWidth: 1,
          borderRadius: 4,
          borderSkipped: false,
          barPercentage: 0.8,
          categoryPercentage: 0.9,
          order: 2,
          type: 'bar',
        },
        {
          label: 'Both Parties',
          data: averagedData.map(d => d.Both ? d.Both - 50 : null),
          borderColor: 'rgba(0, 0, 0, 0.7)',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: true,
          tension: 0.4,
          pointRadius: 4,
          pointHoverRadius: 6,
          pointBackgroundColor: 'rgba(0, 0, 0, 0.8)',
          pointBorderColor: 'rgba(255, 255, 255, 1)',
          pointBorderWidth: 2,
          order: 1,
          type: 'line',
        },
      ],
    };

    console.log(`Final chart data for ${type}:`, chartData);
    if (type === 'senate') {
      setChartDataSenate(chartData);
    } else {
      setChartDataHouse(chartData);
    }
    return { minValue, maxValue };
  }, [data, selectedTopic]);

  useEffect(() => {
    if (data.senate && data.senate.length > 0 && data.house && data.house.length > 0) {
      console.log('Raw data:', data);
      const { minValue: minSenate, maxValue: maxSenate } = processChartData('senate');
      const { minValue: minHouse, maxValue: maxHouse } = processChartData('house');
      const minValue = Math.min(minSenate, minHouse);
      const maxValue = Math.max(maxSenate, maxHouse);
      setChartOptions(prevOptions => ({
        ...prevOptions,
        scales: {
          ...prevOptions.scales,
          x: {
            ...prevOptions.scales.x,
            min: Math.min(minValue, -maxValue) - 5,
            max: Math.max(maxValue, -minValue) + 5,
          }
        }
      }));
    } else {
      console.log('No data available');
      setChartDataSenate(null);
      setChartDataHouse(null);
    }
  }, [data, processChartData]);

  return (
    <div className="party-change-over-time">
      <div className="chart-description">
        <div className="text">Visualize how political parties' positions have evolved on various issues across different congressional terms. Data is based on voting record and sponsored legislation.</div>
      </div>
      <div className="chart-controls">
        <div className="control-group">
          <label htmlFor="topic-select">Topic:</label>
          <select
            id="topic-select"
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
          >
            {topics.map(topic => (
              <option key={topic} value={topic}>{topic}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="charts-container">
        <div className="chart-wrapper">
          <h3>Senate</h3>
          <div className="chart-container">
            {chartDataSenate && (
              <Line
                key={`senate-${selectedTopic}`}
                data={chartDataSenate}
                options={chartOptions}
                plugins={[
                  {
                    id: 'congressInfo',
                    afterDraw: (chart) => {
                      const ctx = chart.ctx;
                      const yAxis = chart.scales.y;
                      const xAxis = chart.scales.x;
                      
                      ctx.save();
                      ctx.textBaseline = 'middle';
                      ctx.font = chart.width < 600 ? '10px Arial' : '12px Arial';

                      chart.data.labels.forEach((label, index) => {
                        const y = yAxis.getPixelForTick(index);
                        const congressNumber = 118 - index;
                        const majorityParty = congressControl['sen'][label];
                        const partyColor = majorityParty === 'Democrat' ? 'rgb(66, 133, 244)' : 'rgb(240, 106, 106)';
                        const partyLetter = majorityParty === 'Democrat' ? 'D' : 'R';

                        const democratValue = chart.data.datasets[0].data[index] || 0;
                        const republicanValue = chart.data.datasets[1].data[index] || 0;

                        let xPosition;
                        const margin = 15; // Adjust this value to increase/decrease the space between label and bar

                        if (majorityParty === 'Democrat') {
                          const barEnd = xAxis.getPixelForValue(Math.max(democratValue, 0));
                          xPosition = Math.min(barEnd - margin, xAxis.getPixelForValue(0) - margin);
                          ctx.textAlign = 'right';
                        } else {
                          const barEnd = xAxis.getPixelForValue(Math.min(republicanValue, 0));
                          xPosition = Math.max(barEnd + margin, xAxis.getPixelForValue(0) + margin);
                          ctx.textAlign = 'left';
                        }

                        ctx.fillStyle = 'black';
                        ctx.fillText(`${congressNumber}th Congress`, xPosition, y - 8);
                        ctx.fillStyle = partyColor;
                        ctx.fillText(`${partyLetter} Majority`, xPosition, y + 8);
                      });

                      ctx.restore();
                    }
                  },
                  {
                    id: 'axisLabels',
                    afterDraw: (chart) => {
                      const { ctx, scales: { x, y } } = chart;
                      ctx.save();
                      ctx.font = '14px Arial';
                      ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
                      ctx.textAlign = 'left';
                      ctx.fillText('More liberal', x.getPixelForValue(x.max), y.bottom + 35); // Increased from 20 to 35
                      ctx.textAlign = 'right';
                      ctx.fillText('More conservative', x.getPixelForValue(x.min), y.bottom + 35); // Increased from 20 to 35
                      ctx.restore();
                    }
                  }
                ]}
                height={600}
              />
            )}
          </div>
        </div>
        <div className="chart-wrapper">
          <h3>House</h3>
          <div className="chart-container">
            {chartDataHouse && (
              <Line
                key={`house-${selectedTopic}`}
                data={chartDataHouse}
                options={chartOptions}
                plugins={[
                  {
                    id: 'congressInfo',
                    afterDraw: (chart) => {
                      const ctx = chart.ctx;
                      const yAxis = chart.scales.y;
                      const xAxis = chart.scales.x;
                      
                      ctx.save();
                      ctx.textBaseline = 'middle';
                      ctx.font = chart.width < 600 ? '10px Arial' : '12px Arial';

                      chart.data.labels.forEach((label, index) => {
                        const y = yAxis.getPixelForTick(index);
                        const congressNumber = 118 - index;
                        const majorityParty = congressControl['rep'][label];
                        const partyColor = majorityParty === 'Democrat' ? 'rgb(66, 133, 244)' : 'rgb(240, 106, 106)';
                        const partyLetter = majorityParty === 'Democrat' ? 'D' : 'R';

                        const democratValue = chart.data.datasets[0].data[index] || 0;
                        const republicanValue = chart.data.datasets[1].data[index] || 0;

                        let xPosition;
                        const margin = 15; // Adjust this value to increase/decrease the space between label and bar

                        if (majorityParty === 'Democrat') {
                          const barEnd = xAxis.getPixelForValue(Math.max(democratValue, 0));
                          xPosition = Math.min(barEnd - margin, xAxis.getPixelForValue(0) - margin);
                          ctx.textAlign = 'right';
                        } else {
                          const barEnd = xAxis.getPixelForValue(Math.min(republicanValue, 0));
                          xPosition = Math.max(barEnd + margin, xAxis.getPixelForValue(0) + margin);
                          ctx.textAlign = 'left';
                        }

                        ctx.fillStyle = 'black';
                        ctx.fillText(`${congressNumber}th Congress`, xPosition, y - 8);
                        ctx.fillStyle = partyColor;
                        ctx.fillText(`${partyLetter} Majority`, xPosition, y + 8);
                      });

                      ctx.restore();
                    }
                  },
                  {
                    id: 'axisLabels',
                    afterDraw: (chart) => {
                      const { ctx, scales: { x, y } } = chart;
                      ctx.save();
                      ctx.font = '14px Arial';
                      ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
                      ctx.textAlign = 'left';
                      ctx.fillText('More liberal', x.getPixelForValue(x.max), y.bottom + 35); // Increased from 20 to 35
                      ctx.textAlign = 'right';
                      ctx.fillText('More conservative', x.getPixelForValue(x.min), y.bottom + 35); // Increased from 20 to 35
                      ctx.restore();
                    }
                  }
                ]}
                height={600}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};