import React, { useMemo, useCallback } from 'react';
import { IssueBar } from '../IssueBar/IssueBar';
import './style.css';

export const ScoreComparison = ({ scores, onPositionChange, onRefreshGallery }) => {
  console.log('ScoreComparison render - scores:', scores);

  const groupedScores = useMemo(() => {
    console.log('Calculating groupedScores');
    if (scores && scores.length > 0) {
      const grouped = scores.reduce((acc, scoreData) => {
        (scoreData.scores || []).forEach(score => {
          if (!acc[score.topic_name]) {
            acc[score.topic_name] = {
              topic_name: score.topic_name,
              p1: score.p1 || scoreData.p1,
              p2: score.p2 || scoreData.p2,
              p1_def: score.p1_def || scoreData.p1_def,
              p2_def: score.p2_def || scoreData.p2_def,
              description: score.description,
              scores: []
            };
          }
          acc[score.topic_name].scores.push({
            ...score,
            rep: scoreData.rep,
            politician_id: scoreData.politician_id,
            display_name: scoreData.display_name,
            party: scoreData.party,
            imageUrl: scoreData.image || scoreData.imageUrl,
            average_score: score.average_score || score.score
          });
        });
        return acc;
      }, {});

      const result = Object.values(grouped).sort((a, b) => 
        (a.scores[0]?.rank || 0) - (b.scores[0]?.rank || 0)
      );
      console.log('Calculated groupedScores:', result);
      return result;
    }
    return [];
  }, [scores]);

  const handlePositionChange = useCallback((topic, index, newScore) => {
    console.log('handlePositionChange called - topic:', topic, 'index:', index, 'newScore:', newScore);
    if (onPositionChange) {
      onPositionChange(topic, index, newScore);
    }
    if (onRefreshGallery) {
      onRefreshGallery();
    }
  }, [onPositionChange, onRefreshGallery]);

  if (!scores || scores.length === 0) {
    console.log('No scores available');
    return <div className="no-content-message">No scores available to display.</div>;
  }

  console.log('Rendering IssueBar components');
  return (
    <div className="score-comparison">
      {groupedScores.map((group, index) => (
        <IssueBar
          key={`${group.topic_name}-${index}`}
          title={group.topic_name}
          head={group.topic_name}
          data={group.scores.map(score => ({
            ...score,
            position: score.average_score,
            rep: score.rep,
            party: score.party,
            imageUrl: score.imageUrl,
            politician_id: score.politician_id
          }))}
          labels={[group.p2, group.p1]}
          tooltips={[group.p2_def, group.p1_def]}
          desc={[group.description]}
          onPositionChange={(topic, index, newScore) => handlePositionChange(group.topic_name, index, newScore)}
          onRefreshGallery={onRefreshGallery}
        />
      ))}
    </div>
  );
};
