import React, { useMemo, useCallback } from 'react';
import { IssueBar } from '../IssueBar/IssueBar';
import './style.css';

export const ScoreComparison = ({ scores, onPositionChange, onRefreshGallery, isUserScore = false }) => {
  const groupedScores = useMemo(() => {
    console.log('Scores received in ScoreComparison:', scores);
    if (scores && scores.length > 0) {
      const grouped = scores.reduce((acc, scoreData) => {
        (scoreData.scores || []).forEach(score => {
          if (!acc[score.topic_name]) {
            acc[score.topic_name] = {
              topic_name: score.topic_name,
              p1: score.p1 || scoreData.p1,
              p2: score.p2 || scoreData.p2,
              p1_def: score.p1_def || scoreData.p1_def,
              p2_def: score.p2_def || scoreData.p2_def,
              description: score.description,
              scores: []
            };
          }
          acc[score.topic_name].scores.push({
            ...score,
            rep: scoreData.rep,
            politician_id: scoreData.politician_id,
            display_name: scoreData.display_name,
            party: scoreData.party,
            imageUrl: scoreData.image || scoreData.imageUrl,
            average_score: score.average_score || score.score,
            year: score.year // Make sure to include the year
          });
        });
        return acc;
      }, {});

      const result = Object.values(grouped).sort((a, b) => 
        (a.scores[0]?.rank || 0) - (b.scores[0]?.rank || 0)
      );
      
      console.log('Grouped scores:', result);
      return result;
    }
    return [];
  }, [scores]);

  const handlePositionChange = useCallback((topic, index, newScore) => {
    if (onPositionChange) {
      onPositionChange(topic, index, newScore);
    }
    if (onRefreshGallery) {
      onRefreshGallery();
    }
  }, [onPositionChange, onRefreshGallery]);

  if (!scores || scores.length === 0) {
    return <div className="no-content-message">No scores available to display.</div>;
  }

  return (
    <div className="score-comparison">
      {groupedScores.map((group, index) => (
        <IssueBar
          key={`${group.topic_name}-${index}`}
          head={group.topic_name}
          issues={group.scores}
          onPositionChange={(topic, index, newScore) => handlePositionChange(group.topic_name, index, newScore)}
          onRefreshGallery={onRefreshGallery}
          isUserScore={isUserScore}
        />
      ))}
    </div>
  );
};