import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { RepCard } from '../RepCard/RepCard';
import { signOut, onAuthStateChanged, getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation, Link } from 'react-router-dom';

export const QuizComponent = ({ quizData, quizDetails, resetQuizSelection, associatedTopic }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [quizFinished, setQuizFinished] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userCreated, setUserCreated] = useState(false);
    const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
    const auth = getAuth();
    

    const history = useHistory();
    const location = useLocation();

    const totalQuestions = quizData.reduce((acc, question) => Math.max(acc, question.Question), 0);


    useEffect(() => {
        if (!userEmail) {

        } else {
            setUserCreated(true);
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsAuthenticated(true);
                localStorage.setItem('userEmail', user.email);
                setUserEmail(user.email);
                
            } else {
                setIsAuthenticated(false);
                
            }
        });
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        if (userCreated) {
            const fetchIssues = async () => {
                if (!userEmail) {
                    console.error("No user email found in localStorage");
                    return;
                }
                try {
                    const response = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
                        method: 'GET',
                        headers: {
                            'x-api-key': process.env.REACT_APP_API_KEY_1,
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    

                } catch (error) {
                    console.error("Error fetching issues:", error);
                }
            };
            fetchIssues();
        }
    }, [userCreated, userEmail]);

    useEffect(() => {
        return () => {
            setCurrentQuestionIndex(0);
            setSelectedAnswers([]);
            setTotalScore(0);
            setTotalCount(0);
            setQuizFinished(false);
        };
    }, [quizData]);

    useEffect(() => {
        
    }, [currentQuestionIndex]);

    const updateUserScore = async () => {
        if (totalCount === 0) return;

        const finalScore = totalScore*100 / (totalCount*2);

        try {
            const response = await fetch(`${window.apiUrl}/userscores`, {
                method: 'PUT',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userEmail,
                    group_name: associatedTopic,
                    score: finalScore,
                }),
            });

            if (!response.ok) {
                console.error("Failed to update user score for associated topic:", associatedTopic);
            } else {
                
            }
        } catch (error) {
            console.error("Error updating associated topic score:", error);
        }
    };

    const handleNext = useCallback(async () => {
        
        
        

        const currentQuestionGroup = quizData.filter(
            (question) => question.Question === currentQuestionIndex + 1
        );
        

        if (currentQuestionGroup.length > 0 && currentQuestionGroup[0].Topic) {
            const topic = currentQuestionGroup[0].Topic;
            

            const questionScore = Object.values(selectedAnswers[currentQuestionIndex] || {}).reduce((acc, curr) => acc + curr, 0);
            

            try {
                const response = await fetch(`${window.apiUrl}/userscores`, {
                    method: 'PUT',
                    headers: {
                        'x-api-key': process.env.REACT_APP_API_KEY_1,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: userEmail,
                        group_name: topic,
                        score: questionScore,
                    }),
                });

                
                if (!response.ok) {
                    console.error("Failed to update user score for topic:", topic);
                } else {
                    
                }
            } catch (error) {
                console.error("Error updating score:", error);
            }
        }

        if (currentQuestionIndex < totalQuestions - 1) {
            const newIndex = currentQuestionIndex + 1;
            
            setCurrentQuestionIndex(newIndex);
        } else {
            
            if (quizDetails.EndingType === 2) {
                await updateUserScore();
            }
            setQuizFinished(true);
            history.push(`/quiz?quizId=${quizDetails.QuizID}`);
        }
    }, [currentQuestionIndex, totalQuestions, quizData, quizDetails, history, updateUserScore, userEmail, selectedAnswers, totalScore, totalCount]);

    const handleAnswerSelect = (subQuestionIndex, selectionResult, countForScoring) => {
        const newSelections = [...selectedAnswers];
        newSelections[currentQuestionIndex] = {
            ...newSelections[currentQuestionIndex],
            [subQuestionIndex]: selectionResult
        };
        setSelectedAnswers(newSelections);

        const newTotalScore = totalScore + selectionResult;
        setTotalScore(newTotalScore);

        const newTotalCount = totalCount + countForScoring;
        setTotalCount(newTotalCount);
    };

    const resetQuiz = () => {
        setCurrentQuestionIndex(0);
        setSelectedAnswers([]);
        setTotalScore(0);
        setTotalCount(0);
        setQuizFinished(false);
        resetQuizSelection();
        history.push('/quiz');
    };

    const currentQuestionGroup = quizData.filter(
        (question) => question.Question === currentQuestionIndex + 1
    );

    if (quizFinished) {
        return (
            <QuizEndScreen 
                resetQuiz={resetQuiz} 
                endingType={quizDetails.EndingType} 
                totalScore={totalScore} 
                totalCount={totalCount}
                userEmail={userEmail} 
                associatedTopic={associatedTopic} 
            />
        );
    }

    return (
        <div className="quiz-layout">
            <div className="quiz-left">
                <ProgressBar
                    current={currentQuestionIndex + 1}
                    total={totalQuestions}
                />
                <h1>{quizDetails.QuizName || 'Quiz'}</h1>
                <p>{quizDetails.QuizDescription || 'Take the quiz to find out more!'}</p>
            </div>
            <div className="quiz-right">
                <QuizContent
                    questionGroup={currentQuestionGroup}
                    onSelectAnswer={handleAnswerSelect}
                    currentAnswers={selectedAnswers[currentQuestionIndex] || {}}
                    onNext={handleNext}
                    isLastQuestion={currentQuestionIndex === totalQuestions - 1}
                    currentQuestionIndex={currentQuestionIndex}
                />
            </div>
        </div>
    );
};

const ProgressBar = ({ current, total }) => {
    return (
        <div className="progress-bar">
            <div
                className="progress-filled"
                style={{ width: `${(current / total) * 100}%` }}
            />
        </div>
    );
};

const QuizContent = ({ questionGroup, onSelectAnswer, currentAnswers, onNext, isLastQuestion, currentQuestionIndex }) => {
    useEffect(() => {
        
    }, [currentQuestionIndex]);

    if (questionGroup.length === 0) return null;

    const mainQuestion = questionGroup[0];
    const isAnswerSelected = Object.keys(currentAnswers).length > 0;

    return (
        <div className="quiz-content">
            <h3>{`Question ${currentQuestionIndex + 1}`}</h3>
            <h4>{mainQuestion.QuestionText}</h4>
            {questionGroup.map((question, index) => (
                <div key={index} className="options">
                    <div className="option">
                        <input
                            type="radio"
                            id={`question-${mainQuestion.Question}-${question.SubQuestion}`}
                            name={`question-${mainQuestion.Question}`}
                            value={question.SelectionResult}
                            count={question.CountForScoring}
                            checked={currentAnswers[index] === question.SelectionResult}
                            onChange={() => onSelectAnswer(index, question.SelectionResult, question.CountForScoring)}
                        />
                        <label htmlFor={`question-${mainQuestion.Question}-${question.SubQuestion}`}>
                            <div className="label-text">{question.ResponseText}</div>
                        </label>
                    </div>
                </div>
            ))}
            <button 
                onClick={onNext} 
                disabled={!isAnswerSelected}
                className={isAnswerSelected ? "active" : "inactive"}
            >
                {isLastQuestion ? 'Finish' : 'Next >'}
            </button>
        </div>
    );
};

import { ScoreComparison } from '../../components/ScoreComparison/ScoreComparison';
import { fetchWikipediaImage } from "../../utils/utils";



const QuizEndScreen = ({ resetQuiz, endingType, totalScore, totalCount, userEmail, associatedTopic }) => {
    const [selectedPolitician, setSelectedPolitician] = useState(null);
    const [topics, setTopics] = useState([]);
    const [image, setImage] = useState("");
    const [comparisonScores, setComparisonScores] = useState([]);

    const finalScore = totalScore / totalCount;

    useEffect(() => {
        if (endingType === 1) {
            fetchSimilarPolitician();
        } else if (endingType === 2) {
            fetchComparisonScores();
        }
    }, [endingType, userEmail]);

    const fetchSimilarPolitician = async () => {
        try {
            const response = await fetch(`${window.apiUrl}/similarpoliticians?email=${userEmail}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch similar politicians');
            }

            const data = await response.json();

            if (data.length > 0) {
                const firstPolitician = data[0];
                fetchPoliticianDetails(firstPolitician.politician_id);
            }
        } catch (error) {
            console.error('Error fetching similar politicians:', error);
        }
    };

    const fetchPoliticianDetails = async (politician_id) => {
        try {
            const response = await fetch(`${window.apiUrl}/politicians?politician_id=${politician_id}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch politician details');
            }

            const details = await response.json();

            if (details.length > 0) {
                const politician = details[0];
                setSelectedPolitician(politician);
                fetchTopicsAndImage(politician.politician_id, politician.display_name, politician.id_wikipedia);
            }
        } catch (error) {
            console.error('Error fetching politician details:', error);
        }
    };

    const fetchTopicsAndImage = async (politician_id, display_name, id_wikipedia) => {
        try {
            const topicsResponse = await fetch(`${window.apiUrl}/topissues?politician_id=${politician_id}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                },
            });

            if (!topicsResponse.ok) {
                throw new Error('Failed to fetch topics');
            }

            const topicsData = await topicsResponse.json();
            const topicsList = topicsData.map(topic => topic.topic_name);
            setTopics(topicsList);

            const imageUrl = await fetchWikipediaImage(id_wikipedia) || await fetchWikipediaImage(display_name);
            setImage(imageUrl);
        } catch (error) {
            console.error('Error fetching topics or Wikipedia image:', error);
        }
    };

    const fetchComparisonScores = async () => {
        try {
            const userResponse = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY_1,
                    'Content-Type': 'application/json'
                }
            });
            const userScores = await userResponse.json();

            const filteredUserScores = userScores.filter(score => score.group_name === associatedTopic);

            const userScoreData = {
                rep: 'You',
                politician_id: 'user',
                scores: filteredUserScores.map(score => ({
                    topic_name: score.group_name,
                    average_score: score.score,
                    description: score.description,
                    p1: score.p1,
                    p2: score.p2,
                    p1_def: score.p1_def,
                    p2_def: score.p2_def
                })),
                image: 'https://via.placeholder.com/150?text=User',
                party: 'Your Party'
            };

            const comparisonData = [userScoreData];
            setComparisonScores(comparisonData);
        } catch (error) {
            console.error("Error fetching comparison scores:", error);
        }
    };

    if (endingType === 1) {
        if (!selectedPolitician) {
            return <div className="quiz-end-screen">Loading...</div>;
        }

        return (
            <div className="quiz-end-screen">
                <div className="quiz-end-title">Your Most Similar Rep</div>
                <div className="quiz-end-box">
                    <Link to={`/rep/${selectedPolitician.politician_id}`} className="rep-card-link">
                        <RepCard
                            politician_id={selectedPolitician.politician_id}
                            maskGroup={image}
                            display_name={selectedPolitician.display_name || `${selectedPolitician.name_first} ${selectedPolitician.name_last}`}
                            name={`${selectedPolitician.name_first} ${selectedPolitician.name_last}`}
                            title={selectedPolitician.party || "Unknown"}
                            state={selectedPolitician.state || "Unknown"}
                            type={selectedPolitician.type || "rep"}
                            nextElection={getPreviousYear(selectedPolitician.end) || "Unknown"}
                            topics={topics}
                            description={`Serving since ${selectedPolitician.start} until ${selectedPolitician.end}`}
                        />
                    </Link>
                </div>
                <button onClick={resetQuiz} className="home-button">
                    Home
                </button>
            </div>
        );
    } else if (endingType === 2) {
        return (
            <div className="quiz-end-screen">
                <div className="quiz-end-title">Your Score for | <strong> {associatedTopic}</strong></div>
                <div className="quiz-end-box">
                    <div className="score-comparison">
                        <ScoreComparison scores={comparisonScores} />
                    </div>
                </div>
                <button onClick={resetQuiz} className="home-button">
                    Home
                </button>
            </div>
        );
    }

    return null;
};

const getPreviousYear = (endDate) => {
    if (!endDate) return "Unknown";
    const year = new Date(endDate).getFullYear();
    return year - 1;
};
