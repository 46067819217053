// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.score-comparison {
    width: 100%;
    max-width: 1512px;
  }
  
  .score-section {
    margin-bottom: 40px;
  }
  
  .score-bar {
    position: relative;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    margin: 10px 0;
  }
  
  .score-bubble {
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rep-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .score-labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
  }
`, "",{"version":3,"sources":["webpack://./src/components/ScoreComparison/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,sCAAsC;IACtC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,WAAW;EACb","sourcesContent":[".score-comparison {\n    width: 100%;\n    max-width: 1512px;\n  }\n  \n  .score-section {\n    margin-bottom: 40px;\n  }\n  \n  .score-bar {\n    position: relative;\n    height: 20px;\n    background-color: #e0e0e0;\n    border-radius: 10px;\n    margin: 10px 0;\n  }\n  \n  .score-bubble {\n    position: absolute;\n    top: -10px;\n    transform: translateX(-50%);\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-color: white;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .rep-image {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n  }\n  \n  .score-labels {\n    display: flex;\n    justify-content: space-between;\n    font-size: 14px;\n    color: #666;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
