export const fetchWikipediaImage = async (wikipediaId) => {
    console.log(`Fetching image for Wikipedia ID: ${wikipediaId}`);
    try {
        const url = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=pageimages&piprop=original&titles=${wikipediaId}&origin=*`;
        console.log(`Fetching from URL: ${url}`);
        const response = await fetch(url);
        const data = await response.json();
        console.log('Wikipedia API response:', data);
        const pages = data.query.pages;
        const pageId = Object.keys(pages)[0];
        const imageUrl = pages[pageId].original?.source || null;
        console.log(`Image URL for ${wikipediaId}: ${imageUrl}`);
        return imageUrl;
    } catch (error) {
        console.error('Error fetching Wikipedia image:', error);
        return null;
    }
};
