import React from 'react';
import { CompareRepsSelection } from '../../components/CompareRepsSelection/CompareRepsSelection'; // Adjust the import path as necessary
import { Banner } from '../../components/Banner/Banner';
import './style.css'; // Ensure this is importing the relevant CSS for styling

export const CompareRepsLanding = () => {
    return (
        <div className="compare-reps-page-landing">

            <CompareRepsSelection />
        </div>
    );
};

export default CompareRepsLanding;
