import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { Line } from 'react-chartjs-2';
import userScoreImage from './flag.webp';



export const IssueBar = ({ title, head, data = [], labels = [], tooltips = [], onPositionChange, onRefreshGallery, desc = [] }) => {
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [averageScores, setAverageScores] = useState({});
  const [activeSection, setActiveSection] = useState(null);
  const [subTopics, setSubTopics] = useState([]);
  const [importantBills, setImportantBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
  }, [desc]);

  const handleThumbMouseDown = (event, index) => {
    if (data[index].rep !== "You") {
      return;
    }

    setDraggingIndex(index);

    const thumb = event.target;
    const track = thumb.parentElement;
    const trackRect = track.getBoundingClientRect();
    const initialClientX = event.clientX || event.touches[0].clientX;
    const initialLeft = thumb.getBoundingClientRect().left - trackRect.left;

    let isFirstMove = true;
    let latestPosition = currentPosition[index] !== undefined ? currentPosition[index] : data[index]?.position || 0;

    const onMove = (moveEvent) => {
      const clientX = moveEvent.clientX || moveEvent.touches[0].clientX;

      if (isFirstMove) {
        isFirstMove = false;
        return;
      }

      const clientDeltaX = clientX - initialClientX;
      const newLeft = initialLeft + clientDeltaX;
      const newPosition = Math.max(0, Math.min(trackRect.width, newLeft));
      latestPosition = (newPosition / trackRect.width) * 100;

      const ItemWidth = track.parentElement.getBoundingClientRect().width;
      const x0 = thumb.parentElement.parentElement.getBoundingClientRect().x;
      let currentPosition = clientX;
      latestPosition = 100 - (currentPosition - x0) / ItemWidth * 100;

      latestPosition = Math.max(0, latestPosition);
      latestPosition = Math.min(100, latestPosition);

      setCurrentPosition((prevState) => ({
        ...prevState,
        [index]: latestPosition,
      }));
    };

    const onEnd = () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      document.removeEventListener('touchmove', onMove);
      document.removeEventListener('touchend', onEnd);
      setDraggingIndex(null);
      onPositionChange(data[index]?.topic, index, latestPosition);
      if (onRefreshGallery) {
        onRefreshGallery();
      }
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', onEnd);

    event.preventDefault();
  };

  const handleThumbnailClick = (item) => {
    if (item.rep === "You") {
      return;
    }
    history.push(`/rep/${item.politician_id}`);
  };

  const handleButtonClick = (section) => {
    if (activeSection === section) {
      setActiveSection(null);
      setExpanded(false);
    } else {
      setActiveSection(section);
      setExpanded(true);
      if (section === 'stats') {
        fetchGraphData();
        fetchAverageScores();
      } else if (section === 'subtopics') {
        fetchSubTopics();
      } else if (section === 'bills') {
        fetchImportantBills();
      }
    }
  };

  const fetchGraphData = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/userscoresummary?group_name=${head}`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response;
      if (!Array.isArray(data)) {
        return;
      }
  
      const buckets = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  
      const calculatePercentages = (counts) => {
        const total = counts.reduce((acc, count) => acc + count, 0);
        return counts.map(count => (count / total) * 100);
      };
  
      const userCounts = buckets.map(bucket => {
        const entry = data.find(entry => entry.party === 'User' && entry.bucket === bucket);
        return entry ? entry.count : 0;
      });
  
      const republicanCounts = buckets.map(bucket => {
        const entry = data.find(entry => entry.party === 'Republican' && entry.bucket === bucket);
        return entry ? entry.count : 0;
      });
  
      const democratCounts = buckets.map(bucket => {
        const entry = data.find(entry => entry.party === 'Democrat' && entry.bucket === bucket);
        return entry ? entry.count : 0;
      });
  
      const userPercentages = calculatePercentages(userCounts);
      const republicanPercentages = calculatePercentages(republicanCounts);
      const democratPercentages = calculatePercentages(democratCounts);
  
      setGraphData({
        labels: buckets.reverse(),
        datasets: [
          {
            label: 'Republican Scores',
            data: republicanPercentages,
            fill: true,
            backgroundColor: 'rgba(240, 106, 106, 0.4)',
            borderColor: 'rgba(240, 106, 106, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: 'Democrat Scores',
            data: democratPercentages,
            fill: true,
            backgroundColor: 'rgba(66, 133, 244, 0.4)',
            borderColor: 'rgba(66, 133, 244, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      });
    } catch (error) {
    }
  };
  

  const fetchAverageScores = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/partyscoresummary?group_name=${head}`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const { data } = response;
      if (!Array.isArray(data)) {
        return;
      }
      const averages = {};
      data.forEach((item) => {
        averages[item.party] = item.average_score || 'N/A';
      });
      setAverageScores(averages);
    } catch (error) {
    }
  };

  const fetchSubTopics = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/grouptopics?group_name=${head}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      setSubTopics(response.data);
    } catch (error) {
    }
  };

  const fetchImportantBills = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${window.apiUrl}/groupbillsvotes?group_name=${head}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      setImportantBills(response.data.slice(0, 5)); // Limit to 5 entries
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const renderVerticalLine = (xPosition, color, label) => (
    <div
      className="vertical-line"
      style={{ left: `${100 - xPosition}%`, borderColor: color }}
    >
      <div className="vertical-line-label" style={{ backgroundColor: color }}>
        {label}
      </div>
    </div>
  );

  return (
    <div className={`parent-container ${expanded ? 'expanded' : ''}`}>
      <div className="title-container">
        <h2 className="issue-title">{head}</h2>
        <div className="button-group">
          <button className={`minimalist-button stats ${activeSection === 'stats' ? 'active' : ''}`} onClick={() => handleButtonClick('stats')}>
            Stats
            <span className={`button-caret ${activeSection === 'stats' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button definition ${activeSection === 'definition' ? 'active' : ''}`} onClick={() => handleButtonClick('definition')}>
            Definition
            <span className={`button-caret ${activeSection === 'definition' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button bills ${activeSection === 'bills' ? 'active' : ''}`} onClick={() => handleButtonClick('bills')}>
            Recent Bills
            <span className={`button-caret ${activeSection === 'bills' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button subtopics ${activeSection === 'subtopics' ? 'active' : ''}`} onClick={() => handleButtonClick('subtopics')}>
            Sub-topics
            <span className={`button-caret ${activeSection === 'subtopics' ? 'expanded' : ''}`}></span>
          </button>
        </div>
      </div>
      <div className="issue-bar-container">
        <div className="issue-bar">
          <div className="issue-track">
            {data.map((item, index) => (
              <div
                key={index}
                className={`issue-thumb ${draggingIndex === index ? 'dragging' : ''}`}
                style={{
                  left: `${100 - (currentPosition[index] !== undefined ? currentPosition[index] : item.position || 0)}%`,
                  borderColor: getBorderColor(item.party),
                  boxShadow: `0 0 10px ${getGlowColor(item.party)}`,
                }}
                onMouseDown={(event) => handleThumbMouseDown(event, index)}
                onTouchStart={(event) => handleThumbMouseDown(event, index)}
                onClick={() => handleThumbnailClick(item)}
              >
                {item.rep === "You" ? (
                  <img src={userScoreImage} alt="User Score Thumb" />
                ) : (
                  item.imageUrl && <img src={item.imageUrl} alt="Thumb" />
                )}
                {item.score !== undefined ? <span className="tooltip">Score: {item.score}</span> : <span className="tooltip">Score not available</span>}
              </div>
            ))}
            {activeSection === 'stats' && expanded && (
              <>
                {averageScores['Democrat'] && renderVerticalLine(averageScores['Democrat'], '#4285f4', 'D')}
                {averageScores['Republican'] && renderVerticalLine(averageScores['Republican'], '#f06a6a', 'R')}
                {averageScores['User'] && renderVerticalLine(averageScores['User'], '#808080', 'U')}
              </>
            )}
          </div>
          {activeSection === 'stats' && expanded && graphData && (
            <div className="expanded-section">
              <Line
                data={graphData}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                      reverse: true,
                    },
                    y: {
                      display: false,
                      reverse: true,
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      tension: 0.4,
                    },
                  },
                }}
                style={{ width: '100%', height: '150px' }}
                className="issue-graph"
              />
              {expanded && (
                <div className="legend-label">
                  Average of <span className="users">users</span>, <span className="republicans">republicans</span>, and <span className="democrats">democrats</span>
                </div>
              )}
            </div>
          )}
          {activeSection === 'definition' && expanded && (
            <div className="expanded-section definition-section">
              <h3>Definition</h3>
              <p>{desc[0] || 'No description available'}</p>
              <div className="definition-labels">
                <div className="definition-label">
                  <h4>{labels[1]}</h4>
                  <p>{tooltips[1]}</p>
                </div>
                <div className="definition-label">
                  <h4>{labels[0]}</h4>
                  <p>{tooltips[0]}</p>
                </div>
              </div>
            </div>
          )}
          {activeSection === 'subtopics' && expanded && (
            <div className="expanded-section subtopics-section">
              <h3>Sub-topics</h3>
              <div className="subtopics-bubbles">
                {subTopics.map((topic, index) => (
                  <div key={index} className="subtopic-bubble">
                    {topic.topic_name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeSection === 'bills' && expanded && (
            <div className="expanded-section bills-section">
              <h3>Recent Bills</h3>
              {isLoading ? (
                <div className="loading-spinner"></div>
              ) : (
                <table className="bills-table">
                  <thead>
                    <tr>
                      <th>Bill ID</th>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importantBills.map((bill, index) => (
                      <tr key={index}>
                        <td>{bill.bill_id}</td>
                        <td>{bill.official_title}</td>
                        <td>{new Date(bill.date).toLocaleDateString()}</td>
                        <td>{bill.result}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="issue-labels">
        {tooltips && tooltips[1] && <span title={tooltips[1]}>{labels[1]}</span>}
        {tooltips && tooltips[0] && <span title={tooltips[0]}>{labels[0]}</span>}
      </div>
    </div>
  );
};

const getBorderColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(123, 6, 30, 0.66)';
    case 'Democrat':
      return '#458BEA';
    case 'Independent':
      return '#808080';
    default:
      return '#000000';
  }
};

const getGlowColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(240, 106, 106, 0.66)';
    case 'Democrat':
      return 'rgba(66, 133, 244, 0.66)';
    case 'Independent':
      return 'rgba(128, 128, 128, 0.5)';
    default:
      return 'rgba(0, 0, 0, 0.5)';
  }
};
