import React from 'react';
import './style.css';
import flag from './flag2.png';  // Import the flag image
import instagramIcon from './instagram.svg';
import facebookIcon from './facebook.svg';
import youtubeIcon from './youtube.svg';
import twitterIcon from './twitter.svg';  // Import the Twitter icon

export const RepresentativeBanner = ({ rep, imageUrl, details }) => {
    // Debugging: Log the district value to the console
    const districtValue = parseInt(details?.district, 10);
    
    

    const getPartyClass = (party) => {
        if (!party) return 'other';
        switch(party.toLowerCase()) {
            case 'democrat':
                return 'democrat';
            case 'republican':
                return 'republican';
            default:
                return 'other';
        }
    };

    const partyClass = getPartyClass(details?.party);

    const socialMediaLinks = [
        { icon: facebookIcon, url: details?.facebook, alt: 'Facebook', prefix: 'https://www.facebook.com/' },
        { icon: twitterIcon, url: details?.twitter, alt: 'Twitter', prefix: 'https://twitter.com/' },
        { icon: youtubeIcon, url: details?.youtube, alt: 'YouTube', prefix: 'https://www.youtube.com/' },
        { icon: instagramIcon, url: details?.instagram, alt: 'Instagram', prefix: 'https://www.instagram.com/' },
    ];

    return (
        <div className={`rep-banner-wrapper ${partyClass}`}>
            <div className="rep-banner-main">
                <div className="flag-container">
                    <img src={flag} alt="Flag" className="flag-image" />
                </div>
                <div className="RepresentativeBanner">
                    {imageUrl && (
                        <img src={imageUrl} alt={rep?.name || "Placeholder"} className="main-image" />
                    )}
                    {!imageUrl && (
                        <img src="https://picsum.photos/1920/609" alt="Placeholder" className="main-image" />
                    )}
                </div>
                <div className="text-container">
                    <div className="name">{rep?.display_name}</div>
                    <div className="party">{details?.party}</div>
                    <div className="info">{rep?.state}</div>

                    
                    {details?.district && !isNaN(districtValue) && districtValue > 0 ? (
                        <div className="info">District: {details.district}</div>
                    ) : (
                        <div className="info">Senator</div> // For debugging, see if this branch is hit
                    )}
                    
                    {details?.end && (
                        <div className="info">
                            {(() => {
                                const currentYear = new Date().getFullYear();
                                const endYear = parseInt(details.end.split('-')[0], 10);
                                if (endYear < currentYear) {
                                    return `Last year in office: ${endYear}`;
                                } else {
                                    return `Next Election: ${endYear - 1}`;
                                }
                            })()}
                        </div>
                    )}
                    
                    <div className="social-media-icons">
                        {socialMediaLinks.map((link, index) => (
                            link.url && (
                                <a 
                                    key={index} 
                                    href={`${link.prefix}${link.url}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="social-icon-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(`${link.prefix}${link.url}`, '_blank', 'noopener,noreferrer');
                                    }}
                                >
                                    <img src={link.icon} alt={link.alt} className="social-icon" />
                                </a>
                            )
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
