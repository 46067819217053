import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "../Button/Button";
import { Logo } from "../Logo/Logo";
import { Search } from "../Search/Search";
import { MenuOverlay } from "../MenuOverlay/MenuOverlay";
import "./style.css";
import { signOut, updatePassword } from "firebase/auth";


export const NavBar = ({ className }) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
        });

        return () => unsubscribe();
    }, [auth]);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
          console.log("User signed out");
          // Clear any local storage or session storage
          localStorage.clear();
          sessionStorage.clear();
          // Reset any global variables (if any)
          // For example: window.globalVar = null;
          // Force a full page reload to reset all React state
          window.location.reload(true);
        }).catch((error) => {
          console.error("Error signing out:", error);
        });
      };

    return (
        <div className="nav-wrapper">
            <div className={`macbook-nav-bar ${className}`}>
                <div className="group">
                    <div className="container">
                        <Link to="/" className="logo-instance">
                            <Logo />
                        </Link>
                        <div className="column">
                            <div className="column-2">

                                <Link to="/about" className="text-wrapper-2">About</Link>
                                <Link to="/find-a-rep" className="text-wrapper-2">Find a Rep</Link>
                                <Link to="/pick-your-views" className="text-wrapper-2">Pick Your Views</Link>
                                <Link to="/compare-reps" className="text-wrapper-2">Compare Reps</Link>
                                <Link to="/quiz" className="text-wrapper-2">Quiz</Link>
                                <Link to="/analytics" className="text-wrapper-2">Analytics</Link>

                                <div className="nav-link-dropdown">
                              
                                    <div className="chevron-down" />
                                    {!isLoggedIn ? (
                                        <>
                                            <Link to="/login">
                                                <Button className="button-login" property1="primary" text="Log in" />
                                            </Link>
                                            <Link to="/signup">
                                                <Button className="button-signup" property1="secondary" text="Sign up" />
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                        <Link to="/profile">
                                            <Button className="button-profile" property1="primary" text="My profile" />
                                        </Link>
                                        <Link to="/">
                                            <Button className="button-out" property1="secondary" onClick={handleLogout} text="Sign out" />
                                        </Link>

</>
                                        
                                        
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-header">
            <Link to="/" className="logo-instance">
                <Logo className="logo" />
                </Link>
                <svg
                    className="menu-icon"
                    onClick={toggleMenu}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                >
                    <path d="M1 3H21" stroke="#458BEA" strokeWidth="2" strokeLinecap="round" />
                    <path d="M1 10H21" stroke="#458BEA" strokeWidth="2" strokeLinecap="round" />
                    <path d="M1 17H21" stroke="#458BEA" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </div>
            <MenuOverlay isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </div>
    );
};
