import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { RepresentativeBanner } from '../../components/RepresentativeBanner/RepresentativeBanner';
import { IssueGallery } from '../../components/IssueGallery/IssueGallery';
import { fetchWikipediaImage } from '../../utils/utils';
import { RepGallery } from '../../components/RepGallery/RepGallery';
import './style.css';

export const RepPage = () => {
  const [issuesData, setIssuesData] = useState([]);
  const [repImageUrl, setRepImageUrl] = useState('');
  const [repDetails, setRepDetails] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rep, setRep] = useState(null);
  const { politicianId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const pageRef = useRef(null);

  useEffect(() => {
    const fetchRepData = async () => {
      if (!politicianId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(`${window.apiUrl}/politicians?politician_id=${politicianId}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const repData = await response.json();
        const politician = repData.length > 0 ? repData[0] : null;

        if (politician) {
          setRep(politician);
          setRepDetails(politician);
          const imageUrl = await fetchWikipediaImage(politician.id_wikipedia) || await fetchWikipediaImage(politician.display_name) || 'https://picsum.photos/1920/609';
          setRepImageUrl(imageUrl);

          // Fetch issues data
          const issuesResponse = await fetch(`${window.apiUrl}/politicianscore?politician_id=${politicianId}`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json'
            }
          });

          if (!issuesResponse.ok) {
            throw new Error(`HTTP error! status: ${issuesResponse.status}`);
          }

          const issues = await issuesResponse.json();
          const formattedIssuesData = issues.map(issue => ({
            ...issue,
            imageUrl: imageUrl,
          }));
          setIssuesData(formattedIssuesData);
        } else {
          setError('Representative not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch representative data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRepData();
  }, [politicianId]);

  const handleCardSelect = useCallback((selectedRep) => {
    history.replace(`/rep/${selectedRep.politician_id}`);
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history]);

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  if (loading) {
    return <div className="loading-icon"><div className="loading-spinner"></div></div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!rep) {
    return <div className="error">Representative not found</div>;
  }

  console.log("Representative's party:", repDetails.party);
  return (
    <div className="rep-page" ref={pageRef}>
      <RepresentativeBanner rep={rep} imageUrl={repImageUrl} details={repDetails} />
      <div className="rep-page-wrap">
        <div className="title-wrap">
          <div className="title">Issues</div>
          <div className="text">{`See where ${rep.display_name} stands on the issues.`}</div>
        </div>
        <IssueGallery className="issue-gallery" issuesData={issuesData} party={repDetails.party} />
        <div className="similarreps">
          <RepGallery 
            title="Similar Reps" 
            description={`See representatives that align most with ${rep.display_name}`}
            dataType="similar-rep" 
            item={politicianId} 
            refreshGallery="" 
            onCardSelect={handleCardSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default RepPage;
