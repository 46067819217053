import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as d3 from 'd3';
import { useHistory } from 'react-router-dom';
import { fetchWikipediaImage } from '../../utils/utils';
import './style.css';

export const ChangeOverTimeChart = ({ initialData, fetchChangeOverTimeData, topics }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('All');
  const currentYear = new Date().getFullYear();
  const [minYear, setMinYear] = useState(2015);
  const [maxYear, setMaxYear] = useState(2019);
  const [minBillCount, setMinBillCount] = useState(50);
  const history = useHistory();
  const chartRef = useRef(null);
  const [error, setError] = useState(null);
  const [additionalReps, setAdditionalReps] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedParty, setSelectedParty] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [parties, setParties] = useState(['All']);
  const [types, setTypes] = useState(['All']);

  useEffect(() => {
    console.log('ChangeOverTimeChart: Component mounted');
    console.log('Initial data:', initialData);
    if (initialData && initialData.length > 0) {
      console.time('processAndSetChartData');
      processAndSetChartData(initialData);
      console.timeEnd('processAndSetChartData');
      const uniqueParties = ['All', ...new Set(initialData.map(item => item.party).filter(Boolean))];
      const uniqueTypes = ['All', ...new Set(initialData.map(item => item.type).filter(Boolean))];
      setParties(uniqueParties);
      setTypes(uniqueTypes);
    }
  }, [initialData]);

  const processAndSetChartData = async (data, isAdditional = false) => {
    console.log('Processing data:', data);
    console.time('processData');
    const processedData = await Promise.all(data.map(async item => {
      console.log('Processing item:', item);
      let imageUrl;
      if (item.id_wikipedia) {
        console.log(`Fetching image for ${item.id_wikipedia} with id_wikipedia: ${item.id_wikipedia}`);
        imageUrl = await fetchWikipediaImage(item.id_wikipedia);
        console.log(`Fetched image URL: ${imageUrl}`);
      } else {
        console.log(`No id_wikipedia for ${item.politician_id}`);
      }
      return {
        ...item,
        start_score: parseFloat(item.start_score),
        end_score: parseFloat(item.end_score),
        difference_score: parseFloat(item.difference_score),
        imageUrl: imageUrl,
        party: item.party || 'Unknown',
        display_name: item.id_wikipedia || item.politician_id
      };
    }));
    console.timeEnd('processData');
    console.log('Processed chart data:', processedData);
    
    if (isAdditional) {
      setAdditionalReps(prev => [...prev, ...processedData]);
    } else {
      setChartData(processedData.slice(0, 20)); // Show up to 20 reps
    }
    
    return processedData;
  };

  useEffect(() => {
    if (chartData.length > 0 || additionalReps.length > 0) {
      console.log('Chart data updated, drawing charts');
      console.time('drawCharts');
      drawCharts();
      console.timeEnd('drawCharts');
    }
  }, [chartData, additionalReps]);

  const fetchChartData = useCallback(async () => {
    const params = {
      topic_name: selectedTopic,
      start_year: minYear.toString(),
      end_year: maxYear.toString(),
      start_bill_count: minBillCount.toString(),
      end_bill_count: minBillCount.toString(),
    };

    if (selectedParty !== 'All') {
      params.party = selectedParty;
    }

    if (selectedType !== 'All') {
      params.type = selectedType;
    }

    try {
      console.log('Fetching chart data with params:', params);
      console.time('fetchChangeOverTimeData');
      const data = await fetchChangeOverTimeData(params);
      console.timeEnd('fetchChangeOverTimeData');
      console.log('Fetched data:', data);
      if (data.length === 0) {
        setError('No data available for this filter selection. Please select different options.');
        setChartData([]);
        setAdditionalReps([]);
      } else {
        setError(null);
        console.time('processAndSetChartData');
        await processAndSetChartData(data);
        console.timeEnd('processAndSetChartData');
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setError(`Failed to fetch chart data. Error: ${error.message}`);
      setChartData([]);
      setAdditionalReps([]);
    }
  }, [selectedTopic, minYear, maxYear, minBillCount, selectedParty, selectedType, fetchChangeOverTimeData]);

  useEffect(() => {
    fetchChartData();
  }, [selectedTopic, minYear, maxYear, minBillCount, selectedParty, selectedType]);

  const drawCharts = () => {
    console.log('Drawing charts with data:', chartData, additionalReps);
    if (chartData.length === 0 && additionalReps.length === 0) {
      console.warn('No data to draw charts');
      return;
    }

    const allData = [...chartData, ...additionalReps];

    const margin = { top: 20, right: 35, bottom: 40, left: 10 }; // Increased bottom margin
    const containerWidth = chartRef.current.clientWidth;
    const totalWidth = containerWidth - margin.left - margin.right;
    const isMobile = window.innerWidth <= 768;
    const leftChartWidth = isMobile ? totalWidth : totalWidth * 0.84;
    const rightChartWidth = isMobile ? 0 : totalWidth * 0.13;
    const chartGap = isMobile ? 0 : totalWidth * 0.03 + 20;
    const rowHeight = 50;
    const totalHeight = Math.max(allData.length, 20) * rowHeight; // Ensure minimum height for 20 rows

    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", containerWidth)
      .attr("height", totalHeight + margin.top + margin.bottom);

    const chartGroup = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define scales
    const x1 = d3.scaleLinear().domain([100, 0]).range([0, leftChartWidth]);
    const y = d3.scaleBand()
      .range([0, totalHeight])
      .domain(allData.map(d => d.display_name || d.politician_id))
      .padding(0.3);

    // Add lighter horizontal gridlines in the background
    const gridlines = chartGroup.append("g")
      .attr("class", "gridlines");

    y.domain().forEach(d => {
      gridlines.append("line")
        .attr("x1", 0)
        .attr("x2", leftChartWidth + chartGap + rightChartWidth)
        .attr("y1", y(d) + y.bandwidth() / 2)
        .attr("y2", y(d) + y.bandwidth() / 2)
        .attr("stroke", "#e8e8e8")
        .attr("stroke-width", 1);
    });

    // Left Chart
    // Add gradient background
    const gradient = chartGroup.append("defs")
      .append("linearGradient")
      .attr("id", "score-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "100%")
      .attr("y2", "0%");

    gradient.append("stop")
      .attr("offset", "0%")
      .attr("stop-color", "#4285f4")
      .attr("stop-opacity", 0.1);

    gradient.append("stop")
      .attr("offset", "100%")
      .attr("stop-color", "#f06a6a")
      .attr("stop-opacity", 0.1);

    chartGroup.append("rect")
      .attr("width", leftChartWidth)
      .attr("height", totalHeight)
      .style("fill", "url(#score-gradient)");

    // Add vertical lines
    const verticalLines = [50, 60, 70, 80, 90, 100];
    verticalLines.forEach(score => {
      chartGroup.append("line")
        .attr("x1", x1(score))
        .attr("x2", x1(score))
        .attr("y1", 0)
        .attr("y2", totalHeight)
        .attr("stroke", score === 50 ? "black" : "#ddd")
        .attr("stroke-width", score === 50 ? 2 : 1)
        .attr("stroke-dasharray", score === 50 ? "none" : "4");
    });

    chartGroup.append("g")
      .attr("transform", `translate(0,${totalHeight})`)
      .call(d3.axisBottom(x1));

    // Lines
    chartGroup.selectAll("myline")
      .data(allData)
      .enter()
      .append("line")
        .attr("x1", d => x1(d.start_score))
        .attr("x2", d => x1(d.end_score))
        .attr("y1", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
        .attr("y2", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
        .attr("stroke", "grey")
        .attr("stroke-width", "2px");

    // Function to create rep image
    const createRepImage = (selection, score, isStart, otherScore) => {
      const imageSize = 36;
      const imageBorderWidth = 3;
      const labelWidth = 100; // Adjust this value based on your needs

      const imageContainer = selection
        .append("foreignObject")
        .attr("class", "image-container")
        .attr("width", imageSize + 20)
        .attr("height", imageSize + 20)
        .attr("x", d => x1(score(d)) - (imageSize + 20) / 2)
        .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2 - (imageSize + 20) / 2);

      const imageGroup = imageContainer
        .append("xhtml:div")
        .attr("class", "image-group")
        .style("width", "100%")
        .style("height", "100%")
        .style("display", "flex")
        .style("align-items", "center")
        .style("justify-content", "center");

      const imageBorder = imageGroup.append("div")
        .attr("class", "image-border")
        .style("width", `${imageSize}px`)
        .style("height", `${imageSize}px`)
        .style("border-radius", "50%")
        .style("border", d => `${imageBorderWidth}px solid ${getBorderColor(d.party)}`)
        .style("overflow", "hidden")
        .style("flex-shrink", "0");

      imageBorder.append("img")
        .attr("src", d => d.imageUrl || "https://via.placeholder.com/36")
        .style("width", "100%")
        .style("height", "100%")
        .style("object-fit", "cover")
        .style("opacity", isStart ? 0.6 : 1)
        .on("click", (event, d) => handlePoliticianClick(d.politician_id));

      // Add label only for the image with higher score
      if (score(selection.datum()) >= otherScore(selection.datum())) {
        selection.append("text")
          .attr("class", "image-label")
          .attr("x", d => x1(score(d)) - (imageSize + 20) / 2 - 10) // Position to the left of the image
          .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
          .attr("text-anchor", "end")
          .attr("dominant-baseline", "middle")
          .text(d => d.display_name || d.politician_id);
      }
    };

    // Create images and labels
    allData.forEach((d, i) => {
      const startScore = d.start_score;
      const endScore = d.end_score;

      // Create start score image
      createRepImage(
        chartGroup.append("g").datum(d),
        () => startScore,
        true,
        () => endScore
      );

      // Create end score image
      createRepImage(
        chartGroup.append("g").datum(d),
        () => endScore,
        false,
        () => startScore
      );

      // Add arrow in the middle of the line
      const arrowSize = 6;
      const midX = (x1(startScore) + x1(endScore)) / 2;
      const midY = y(d.display_name || d.politician_id) + y.bandwidth() / 2;

      chartGroup.append("path")
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(arrowSize * arrowSize))
        .attr("transform", `translate(${midX}, ${midY}) rotate(${startScore <= endScore ? 270 : 90})`)
        .attr("fill", "grey");
    });

    // Right Chart (only for non-mobile view)
    if (!isMobile) {
      const maxDifference = d3.max(allData, d => Math.abs(d.difference_score));
      const x2 = d3.scaleLinear().domain([maxDifference, -maxDifference]).range([0, rightChartWidth]);

      const colorScale = d3.scaleLinear()
        .domain([-maxDifference, 0, maxDifference])
        .range(["#f06a6a", "#FFFFFF", "#4285f4"]);

      const secondChartG = chartGroup.append("g")
        .attr("transform", `translate(${leftChartWidth + chartGap}, 0)`);

      secondChartG.append("g")
        .attr("transform", `translate(0,${totalHeight})`)
        .call(d3.axisBottom(x2));

      // Center line
      const centerX = x2(0);
      secondChartG.append("line")
        .attr("x1", centerX)
        .attr("x2", centerX)
        .attr("y1", 0)
        .attr("y2", totalHeight)
        .attr("stroke", "black")
        .attr("stroke-width", "2px");

      // Bars for difference score
      secondChartG.selectAll(".diff-bar")
        .data(allData)
        .enter()
        .append("rect")
          .attr("class", "diff-bar")
          .attr("x", d => d.difference_score > 0 ? x2(d.difference_score) : x2(0))
          .attr("y", d => y(d.display_name || d.politician_id))
          .attr("width", d => Math.abs(x2(d.difference_score) - x2(0)))
          .attr("height", y.bandwidth())
          .attr("fill", d => colorScale(d.difference_score));

      // Difference score labels
      secondChartG.selectAll(".diff-label")
        .data(allData)
        .enter()
        .append("text")
          .attr("class", "diff-label")
          .attr("x", d => d.difference_score >= 0 ? x2(d.difference_score) - 5 : x2(d.difference_score) + 5)
          .attr("y", d => y(d.display_name || d.politician_id) + y.bandwidth() / 2)
          .attr("text-anchor", d => d.difference_score >= 0 ? "end" : "start")
          .attr("dominant-baseline", "middle")
          .text(d => d.difference_score >= 0 ? `+${d.difference_score.toFixed(2)}` : `${d.difference_score.toFixed(2)}`)
          .style("font-size", "10px")
          .style("fill", "black");

      // Add title for the second chart
      secondChartG.append("text")
        .attr("x", rightChartWidth / 2)
        .attr("y", -10)
        .attr("text-anchor", "middle")
        .style("font-size", "14px")
        .style("font-weight", "bold")
        .text("(Relative)");
    }



    // Add titles to the charts
    chartGroup.append("text")
      .attr("x", leftChartWidth / 2)
      .attr("y", -10)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .text("Rep voting behavior shift over time (Absolute)");

    // Add labels below the left chart
    chartGroup.append("text")
      .attr("x", 0)
      .attr("y", totalHeight + 30) // Adjusted y-position
      .attr("text-anchor", "start")
      .style("font-size", "12px")
      .text("More Liberal");

    chartGroup.append("text")
      .attr("x", leftChartWidth)
      .attr("y", totalHeight + 30) // Adjusted y-position
      .attr("text-anchor", "end")
      .style("font-size", "12px")
      .text("More Conservative");

    console.log('Charts drawn successfully');
  };

  const handlePoliticianClick = (politicianId) => {
    history.push(`/rep/${politicianId}`);
  };

  const getBorderColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgba(123, 6, 30, 0.66)';
      case 'Democrat':
        return '#458BEA';
      case 'Independent':
        return '#808080';
      default:
        return '#000000';
    }
  };

  const handleSearch = useCallback(async () => {
    if (searchTerm.length < 3) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await fetch(`${window.apiUrl}/politiciansearch?search=${searchTerm}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching for politicians:', error);
      setError('Failed to search for politicians. Please try again.');
    }
  }, [searchTerm]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, handleSearch]);

  const isRepSelectable = (politician) => {
    console.log('Checking if rep is selectable:', politician);
    const politicianFullStart = new Date(politician.full_start).getFullYear();
    const politicianFullEnd = new Date(politician.full_end).getFullYear();
    const isSelectable = politicianFullEnd >= maxYear && politicianFullStart <= minYear;
    console.log(`Rep ${politician.display_name} selectable: ${isSelectable}`);
    console.log(`Politician full years: ${politicianFullStart}-${politicianFullEnd}, Selected range: ${minYear}-${maxYear}`);
    return isSelectable;
  };

  const addRepresentative = async (politician) => {
    try {
      const params = {
        topic_name: selectedTopic,
        start_year: minYear.toString(),
        end_year: maxYear.toString(),
        start_bill_count: minBillCount.toString(),
        end_bill_count: minBillCount.toString(),
        politician_id: politician.politician_id
      };
      const data = await fetchChangeOverTimeData(params);
      if (data.length > 0) {
        await processAndSetChartData(data, true);
      }
    } catch (error) {
      console.error('Error adding representative:', error);
      setError('Failed to add representative. Please try again.');
    }
  };

  const handleMinYearChange = (event) => {
    const newMinYear = parseInt(event.target.value);
    setMinYear(newMinYear);
    if (newMinYear > maxYear) {
      setMaxYear(newMinYear);
    }
  };

  const handleMaxYearChange = (event) => {
    const newMaxYear = parseInt(event.target.value);
    setMaxYear(newMaxYear);
    if (newMaxYear < minYear) {
      setMinYear(newMaxYear);
    }
  };

  return (
    <div className="change-over-time-chart">
      <h2>Change in Politician Scores Over Time</h2>
      <div className="chart-controls">
        <div className="control-group">
          <label htmlFor="topic-select">Topic:</label>
          <select id="topic-select" value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
            {topics.map((topic) => (
              <option key={topic} value={topic}>{topic}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="min-year">Start Year: {minYear}</label>
          <input
            id="min-year"
            type="range"
            min="2000"
            max="2023"
            value={minYear}
            onChange={handleMinYearChange}
          />
        </div>
        <div className="control-group">
          <label htmlFor="max-year">End Year: {maxYear}</label>
          <input
            id="max-year"
            type="range"
            min="2000"
            max="2023"
            value={maxYear}
            onChange={handleMaxYearChange}
          />
        </div>
        <div className="control-group">
          <label htmlFor="min-bill-count">Bill's sponsored/voted: {minBillCount}</label>
          <input
            id="min-bill-count"
            type="range"
            min="1"
            max="200"
            value={minBillCount}
            onChange={(e) => setMinBillCount(parseInt(e.target.value))}
          />
        </div>
        <div className="control-group">
          <label htmlFor="party-select">Party:</label>
          <select id="party-select" value={selectedParty} onChange={(e) => setSelectedParty(e.target.value)}>
            {parties.map((party) => (
              <option key={party} value={party}>{party}</option>
            ))}
          </select>
        </div>
        <div className="control-group">
          <label htmlFor="type-select">Type:</label>
          <select id="type-select" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            {types.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      {(chartData.length > 0 || additionalReps.length > 0) ? (
        <div className="chart-container" ref={chartRef}></div>
      ) : (
        <div className="no-data-message">No data available. Please adjust your filter criteria.</div>
      )}
      {/* Search functionality remains hidden */}
    </div>
  );
};