import React from 'react';
import './style.css';
import flag from './flag2.png';  // Import the flag image
import logos from './logos.png';  // Import the logos image

export const RepresentativeBanner = ({ rep, imageUrl, details }) => {
    // Debugging: Log the district value to the console
    const districtValue = parseInt(details?.district, 10);
    console.log('Raw District value:', details?.district);
    console.log('Parsed District value:', districtValue);

    const getPartyClass = (party) => {
        if (!party) return 'other';
        switch(party.toLowerCase()) {
            case 'democrat':
                return 'democrat';
            case 'republican':
                return 'republican';
            default:
                return 'other';
        }
    };

    const partyClass = getPartyClass(details?.party);

    return (
        <div className={`rep-banner-wrapper ${partyClass}`}>
            <div className="rep-banner-main">
                <div className="flag-container">
                    <img src={flag} alt="Flag" className="flag-image" />
                </div>
                <div className="RepresentativeBanner">
                    {imageUrl && (
                        <img src={imageUrl} alt={rep?.name || "Placeholder"} className="main-image" />
                    )}
                    {!imageUrl && (
                        <img src="https://picsum.photos/1920/609" alt="Placeholder" className="main-image" />
                    )}
                </div>
                <div className="text-container">
                    <div className="name">{rep?.display_name}</div>
                    <div className="party">{details?.party}</div>
                    <div className="info">{rep?.state}</div>

                    
                    {details?.district && !isNaN(districtValue) && districtValue > 0 ? (
                        <div className="info">District: {details.district}</div>
                    ) : (
                        <div className="info">Senator</div> // For debugging, see if this branch is hit
                    )}
                    
                    {details?.end && (
                        <div className="info">
                            {(() => {
                                const currentYear = new Date().getFullYear();
                                const endYear = parseInt(details.end.split('-')[0], 10);
                                if (endYear < currentYear) {
                                    return `Last year in office: ${endYear}`;
                                } else {
                                    return `Next Election: ${endYear - 1}`;
                                }
                            })()}
                        </div>
                    )}
                </div>
                <img src={logos} alt="Icons" className="icons" />
            </div>
        </div>
    );
};
