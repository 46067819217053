import React, { useState, useEffect, useCallback } from 'react';
import { Banner } from '../../components/Banner/Banner';
// import { TopBillSponsorsGraph } from '../../components/TopBillSponsorsGraph/TopBillSponsorsGraph';
import { ChangeOverTimeChart } from '../../components/ChangeOverTimeChart/ChangeOverTimeChart';
import './style.css';

export const Analytics = () => {
  // const [topBillSponsors, setTopBillSponsors] = useState([]);
  const [groupDefinitions, setGroupDefinitions] = useState([]);
  const [changeOverTimeData, setChangeOverTimeData] = useState([]);
  const [topics, setTopics] = useState(['All']);
  const [error, setError] = useState(null);

  const fetchChangeOverTimeData = useCallback(async (params) => {
    try {
      console.log('Fetching change over time data with params:', params);
      const response = await fetch(`${window.apiUrl}/changeovertime?${new URLSearchParams(params)}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Received data:', data);
      return data;
    } catch (error) {
      console.error('Error in fetchChangeOverTimeData:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log('Analytics: Fetching initial data');
      try {
        console.time('fetchAllData');
        const [definitionsResponse, initialChangeOverTimeResponse] = await Promise.all([
          // Commented out TopBillSponsors fetch
          // fetch(`${window.apiUrl}/topbillsponsors`, {
          //   headers: {
          //     'x-api-key': process.env.REACT_APP_API_KEY_1,
          //     'Content-Type': 'application/json',
          //   },
          // }),
          fetch(`${window.apiUrl}/groupdefinitions`, {
            headers: {
              'x-api-key': process.env.REACT_APP_API_KEY_1,
              'Content-Type': 'application/json',
            },
          }),
          fetchChangeOverTimeData({
            topic_name: 'All',
            start_year: new Date().getFullYear() - 5,
            end_year: new Date().getFullYear(),
            start_bill_count: 50,
            end_bill_count: 50
          })
        ]);
        console.timeEnd('fetchAllData');

        if (!definitionsResponse.ok) {
          throw new Error('Failed to fetch data');
        }

        // const sponsorsData = await sponsorsResponse.json();
        const definitionsData = await definitionsResponse.json();

        // setTopBillSponsors(sponsorsData);
        setGroupDefinitions(definitionsData);
        setChangeOverTimeData(initialChangeOverTimeResponse);
        setTopics(['All', ...definitionsData.map(group => group.group_name)]);
        console.log('Initial change over time data:', initialChangeOverTimeResponse);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    fetchData();
  }, [fetchChangeOverTimeData]);

  if (process.env.REACT_APP_NODE_ENV === 'prdoduction') {
    return (
      <div className="analytics-page-wrapper">
        <Banner title="Analytics" />
        <div className="analytics-page-main">
          <div className="title-wrap">
            <div className="title">Political Analytics</div>
            <div className="text">Coming soon!</div>
          </div>
        </div>
      </div>
    );
  }

  if (process.env.REACT_APP_NODE_ENV === 'local' || process.env.REACT_APP_NODE_ENV === 'production') {
    if (error) {
      return <div>Error: {error}</div>;
    }

    return (
      <div className="analytics-page-wrapper">
        <Banner title="Analytics" />
        <div className="analytics-page-main">
          <div className="title-wrap">
            <div className="title">Political Analytics</div>
            <div className="text">Explore various political analytics and insights.</div>
          </div>
          <div className="graphs-container">
            <ChangeOverTimeChart 
              initialData={changeOverTimeData} 
              fetchChangeOverTimeData={fetchChangeOverTimeData}
              topics={topics}
            />
            {/* Commented out TopBillSponsorsGraph */}
            {/* <TopBillSponsorsGraph data={topBillSponsors} /> */}
          </div>
        </div>
      </div>
    );
  }

  // Default return for other environments
  return null;
};