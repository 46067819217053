import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { createOrGetUser } from './utils/auth';
import { NavBar } from './components/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import './index.css';
import { FindARep } from './pages/FindARep/FindARep';
import { CompareReps } from './pages/CompareReps/CompareReps';
import { HomePage } from './pages/HomePage/HomePage';
import { Dev } from './pages/Dev/Dev';
import { Login } from './pages/Login/Login';
import { Signup } from './pages/Signup/Signup';
import { Profile } from './pages/Profile/Profile';
import { PickYourViews } from './pages/PickYourViews/PickYourViews';
import { RepPage } from './pages/RepPage/RepPage';
import { About } from './pages/About/About';
import { Quiz } from './pages/Quiz/Quiz';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService/TermsOfService';
import { CompareRepsLanding } from './pages/CompareRepsLanding/CompareRepsLanding';
import './styleguide.css';
import './utils/firebase'; // Ensure this import is present to initialize Firebase
import { Analytics } from './pages/Analytics/Analytics';

const AppContent = () => {
  const [userEmail, setUserEmail] = useState(null);
  const [pickYourViewsKey, setPickYourViewsKey] = useState(0);
  const location = useLocation();
  const hideHeaderFooterRoutes = ['/landing-page'];

  const initUser = useCallback(async () => {
    const email = await createOrGetUser();
    setUserEmail(prevEmail => {
      if (prevEmail !== email) {
        return email;
      }
      return prevEmail;
    });
  }, []);

  useEffect(() => {
    initUser();
  }, [initUser]);

  useEffect(() => {
    if (location.pathname === '/pick-your-views') {
      setPickYourViewsKey(prevKey => prevKey + 1);
    }
  }, [location]);

  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
      {!shouldHideHeaderFooter && <NavBar userEmail={userEmail} />}
      <main className="main-content">
        <Switch>
          <Route path="/rep/:politicianId" component={RepPage} />
          <Route path="/compare-reps" component={CompareReps} />
          <Route path="/find-a-rep" exact component={FindARep} />
          <Route path="/dev" exact component={Dev} />
          <Route path="/login" exact component={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/pick-your-views" exact render={(props) => (
            <PickYourViews 
              key={`${pickYourViewsKey}-views`}
              {...props} 
              userEmail={userEmail} 
            />
          )} />
          <Route path="/quiz" exact component={Quiz} />
          <Route path="/analytics" exact component={Analytics} />
          <Route path="/" exact component={HomePage} />
          <Route path="/about" exact component={About} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-of-service" exact component={TermsOfService} />
          <Route path="/landing-page" exact component={CompareRepsLanding} />
        </Switch>
      </main>
      {!shouldHideHeaderFooter && <Footer />}
    </>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
