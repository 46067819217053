import React from 'react';
import './style.css';

export const ChartSelector = ({ selectedChart, setSelectedChart }) => {
  const charts = [
    { id: 'change-over-time', name: 'Change Over Time' },
    // Temporarily hiding Majority Alignment
    // { id: 'majority-alignment', name: 'Majority Alignment' },
    { id: 'representative-effectiveness', name: 'Representative Effectiveness' },
    { id: 'party-change-over-time', name: 'Party Change Over Time' }
  ];

  return (
    <div className="chart-selector">
      {charts.map(chart => (
        <div
          key={chart.id}
          className={`chart-bubble ${selectedChart === chart.id ? 'selected' : ''}`}
          onClick={() => setSelectedChart(chart.id)}
        >
          {chart.name}
        </div>
      ))}
    </div>
  );
};