import React, { useEffect } from 'react';
import { FindARepBase } from '../../components/FindARepBase/FindARepBase';
import { RepGallery } from '../../components/RepGallery/RepGallery';
import './style.css';

export const FindARep = () => {
  const userEmail = localStorage.getItem('userEmail');
  const isTempAccount = userEmail && userEmail.includes('@votesee.temp.org');

  useEffect(() => {
    
    
  }, [userEmail, isTempAccount]);

  const localRepsTitle = isTempAccount ? "Local Reps" : "My Reps";
  const localRepsDescription = isTempAccount
    ? "Quickly see the Congress people currently representing your local area. For more accurate results, please sign-in or create an account."
    : "Quickly see the Congress people currently representing you based on the address you provide.";

  return (
    <div className="home-page1">
      <FindARepBase />
      <RepGallery 
        title={localRepsTitle} 
        description={localRepsDescription} 
        dataType="local" 
        item={userEmail} 
      />
      <RepGallery 
        title="Most Searched Reps" 
        description="See which Congressional Representatives are searched most often by the VoteSee community." 
        dataType="top" 
      />
    </div>
  );
};
