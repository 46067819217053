import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";



export const createOrGetUser = async () => {
  const auth = getAuth();
  
  return new Promise((resolve) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        localStorage.setItem('isTemporaryUser', 'false');
        resolve(user.email);
      } else {
        const storedTempEmail = localStorage.getItem('tempUserEmail');
        if (storedTempEmail) {
          localStorage.setItem('isTemporaryUser', 'true');
          resolve(storedTempEmail);
        } else {
          const tempUser = await createTemporaryUser();
          localStorage.setItem('isTemporaryUser', 'true');
          localStorage.setItem('tempUserEmail', tempUser);
          resolve(tempUser);
        }
      }
    });
  });
};

// Modify createTemporaryUser to use the stored email if it exists
const createTemporaryUser = async () => {
  const storedTempEmail = localStorage.getItem('tempUserEmail');
  const tempEmail = storedTempEmail || `${uuidv4()}@votesee.temp.org`;
  const approximateLocation = await getApproximateLocation();
  const tempUserData = {
    email: tempEmail,
    zip_code: approximateLocation.zip || '00000',
    state: approximateLocation.state || 'Unknown',
    city: approximateLocation.city || 'Unknown',
    approximate_location: approximateLocation,
  };

  try {
    const response = await fetch(`${window.apiUrl}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY_1,
      },
      body: JSON.stringify(tempUserData),
    });

    if (!response.ok) {
      throw new Error('Failed to create temporary user');
    }

    localStorage.setItem('userEmail', tempEmail);
    localStorage.setItem('tempUser', 'true');
    return tempEmail;
  } catch (error) {
    console.error('Error creating temporary user:', error);
    return null;
  }
};

const getApproximateLocation = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return {
      country: data.country_name,
      state: getStateAbbreviation(data.region),
      city: data.city,
      zip: data.postal,
      latitude: data.latitude,
      longitude: data.longitude
    };
  } catch (error) {
    console.error('Error fetching approximate location:', error);
    return {
      country: 'Unknown',
      state: 'Unknown',
      city: 'Unknown',
      zip: '00000',
      latitude: null,
      longitude: null
    };
  }
};

const getStateAbbreviation = (fullStateName) => {
  const stateAbbreviations = {
    'Alabama': 'AL', 'Alaska': 'AK', 'Arizona': 'AZ', 'Arkansas': 'AR', 'California': 'CA',
    'Colorado': 'CO', 'Connecticut': 'CT', 'Delaware': 'DE', 'Florida': 'FL', 'Georgia': 'GA',
    'Hawaii': 'HI', 'Idaho': 'ID', 'Illinois': 'IL', 'Indiana': 'IN', 'Iowa': 'IA',
    'Kansas': 'KS', 'Kentucky': 'KY', 'Louisiana': 'LA', 'Maine': 'ME', 'Maryland': 'MD',
    'Massachusetts': 'MA', 'Michigan': 'MI', 'Minnesota': 'MN', 'Mississippi': 'MS', 'Missouri': 'MO',
    'Montana': 'MT', 'Nebraska': 'NE', 'Nevada': 'NV', 'New Hampshire': 'NH', 'New Jersey': 'NJ',
    'New Mexico': 'NM', 'New York': 'NY', 'North Carolina': 'NC', 'North Dakota': 'ND', 'Ohio': 'OH',
    'Oklahoma': 'OK', 'Oregon': 'OR', 'Pennsylvania': 'PA', 'Rhode Island': 'RI', 'South Carolina': 'SC',
    'South Dakota': 'SD', 'Tennessee': 'TN', 'Texas': 'TX', 'Utah': 'UT', 'Vermont': 'VT',
    'Virginia': 'VA', 'Washington': 'WA', 'West Virginia': 'WV', 'Wisconsin': 'WI', 'Wyoming': 'WY'
  };

  return stateAbbreviations[fullStateName] || fullStateName;
};

export const updateTemporaryUser = async (oldEmail, newEmail, password) => {
  try {
    console.log('Updating temporary user:', oldEmail, 'to', newEmail);

    // Update the user in the database
    const response = await fetch(`${window.apiUrl}/users`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY_1,
      },
      body: JSON.stringify({
        email: oldEmail,
        new_email: newEmail,
        username: newEmail.split('@')[0], // Use part of the email as username
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to update user in database:', errorText);
      throw new Error(`Failed to update temporary user in the database: ${errorText}`);
    }

    console.log('User updated in database successfully');

    // Create a new Firebase account with the new email
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, newEmail, password);
    console.log('New Firebase account created successfully');

    // Sign in with the new account
    await signInWithEmailAndPassword(auth, newEmail, password);
    console.log('Signed in with new Firebase account');

    // Update local storage
    localStorage.setItem('userEmail', newEmail);
    localStorage.removeItem('tempUser');
    localStorage.setItem('isTemporaryUser', 'false');

    console.log('Temporary user updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating temporary user:', error);
    throw error; // Re-throw the error to be caught in Setup1.js
  }
};

export const isTemporaryUser = () => {
  return localStorage.getItem('isTemporaryUser') === 'true';
};