import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Logo } from "../Logo/Logo";
import "./style.css";
import twitterIcon from './twitter-icon.svg';
import instagramIcon from './instagram-icon.svg';
import tiktokIcon from './tiktok-icon.svg';
import youtubeIcon from './Youtube.svg';

export const Footer = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  React.useEffect(() => {
    scrollToTop();
  }, [pathname]);

  const handleInternalLinkClick = (path) => (e) => {
    e.preventDefault();
    scrollToTop();
    setTimeout(() => history.push(path), 300);
  };

  const socialLinks = [
    { href: "https://twitter.com/VoteSeeUS", icon: twitterIcon, alt: "Twitter" },
    { href: "https://instagram.com/VoteSeeUS", icon: instagramIcon, alt: "Instagram" },
    { href: "https://tiktok.com/@VoteSee", icon: tiktokIcon, alt: "TikTok" },
    { href: "https://www.youtube.com/@VoteSee/videos", icon: youtubeIcon, alt: "YouTube" },
  ];

  const mainLinks = [
    { href: "/about", text: "About" },
    { href: "/find-a-rep", text: "Find a Rep" },
    { href: "/pick-your-views", text: "Pick Your Views" },
    { href: "/compare-reps", text: "Compare Reps" },
    { href: "/quiz", text: "Quiz" },
  ];

  const secondaryLinks = [
    { href: "/privacy-policy", text: "Privacy Policy" },
    { href: "/terms-of-service", text: "Terms of Service" },
  ];

  return (
    <footer className="footer-wrapper">
      <div className="footer">
        <div className="footer-content">
          <div className="footer-column">
            <Logo className="footer-logo" />
            <div className="footer-info">
              <div className="footer-contact">
                <div className="footer-label">Contact:</div>
                <a href="mailto:votesee.team@gmail.com">votesee.team@gmail.com</a>
              </div>
              <div className="social-links">
                {socialLinks.map((link, index) => (
                  <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                    <img src={link.icon} alt={link.alt} />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <nav className="footer-links-column">
            {mainLinks.map((link, index) => (
              <Link key={index} to={link.href} onClick={handleInternalLinkClick(link.href)}>
                {link.text}
              </Link>
            ))}
          </nav>
        </div>
        <div className="footer-credits">
          <div className="divider" />
          <div className="credits-row">
            <p className="credits-text">© 2024 VoteSee. All rights reserved.</p>
            <div className="footer-links-secondary">
              {secondaryLinks.map((link, index) => (
                <Link key={index} to={link.href} onClick={handleInternalLinkClick(link.href)}>
                  {link.text}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
