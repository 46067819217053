import React, { useState, useEffect } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import "./style.css";
import "../../styleguide.css";
import { Banner } from '../../components/Banner/Banner';
import { ScoreComparison } from '../../components/ScoreComparison/ScoreComparison';
import { MyIssues } from '../../components/MyIssues/MyIssues';
import { signOut, getAuth } from "firebase/auth";
import { RepGallery } from '../../components/RepGallery/RepGallery';
import { usePoliticianData } from '../../hooks/usePoliticianData';

const genericUserIconUrl = 'https://via.placeholder.com/150?text=User';

export const PickYourViews = ({ userEmail }) => {
  const [issues, setIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [scores, setScores] = useState([]);
  const [refreshGallery, setRefreshGallery] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  // Add this line
  const { politicians, error, loading, refetch } = usePoliticianData('similar-me', userEmail, refreshGallery);
  const auth = getAuth();

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        // Your fetch logic here
        // For example:
        // const response = await fetch(...);
        // const fetchedData = await response.json();
        
        if (isMounted) {
          // Update state based on fetched data
          // setIssues(fetchedData.issues);
          // setScores(fetchedData.scores);
          // ... update other state as needed
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (userEmail) {
      fetchIssues();
      fetchScores();
    }
  }, [userEmail]);

  const fetchIssues = async () => {
    if (!userEmail) {
      return;
    }
    try {
      const response = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();

      const sortedIssues = data.sort((a, b) => a.rank - b.rank);
      setIssues(sortedIssues);

      const favoriteIssues = sortedIssues
        .filter(issue => issue.favorite && issue.favorite.data && issue.favorite.data[0] === 1)
        .map(issue => issue.group_name);

      setSelectedIssues(favoriteIssues);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching issues:", error);
    }
  };

  const fetchScores = async () => {
    if (!userEmail) {
      return;
    }
    try {
      const userResponse = await fetch(`${window.apiUrl}/userscores?email=${userEmail}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const userScores = await userResponse.json();

      const sortedScores = userScores.sort((a, b) => a.rank - b.rank);

      const userScoreData = {
        rep: 'You',
        politician_id: 'user',
        display_name: 'You',
        scores: sortedScores.map(score => ({
          topic_name: score.group_name,
          average_score: score.score,
          description: score.description,
          p1: score.p1,
          p2: score.p2,
          p1_def: score.p1_def,
          p2_def: score.p2_def,
          rank: score.rank
        })),
        image: genericUserIconUrl,
        party: 'Your Party'
      };

      setScores([userScoreData]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching scores:", error);
    }
  };

  const toggleIssue = async (issue) => {
    const updatedSelectedIssues = selectedIssues.includes(issue)
      ? selectedIssues.filter(item => item !== issue)
      : [...selectedIssues, issue];

    setSelectedIssues(updatedSelectedIssues);

    try {
      const response = await fetch(`${window.apiUrl}/userscores`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: userEmail,
          group_name: issue,
          favorite: updatedSelectedIssues.includes(issue) ? 1 : 0,
        }),
      });

      if (!response.ok) {
        console.error("Failed to update favorite status");
      } else {
        // Force refresh of RepGallery
        setRefreshGallery(prev => !prev);
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  const handlePositionChange = async (topic, index, newPosition) => {
    const updatedScores = [...scores];
    const scoreIndex = updatedScores[0].scores.findIndex(score => score.topic_name === topic);
    if (scoreIndex !== -1) {
      updatedScores[0].scores[scoreIndex].average_score = newPosition;
      setScores(updatedScores);

      try {
        const response = await fetch(`${window.apiUrl}/userscores`, {
          method: 'PUT',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: userEmail,
            group_name: updatedScores[0].scores[scoreIndex].topic_name,
            score: newPosition,
          }),
        });

        if (response.ok) {
          setRefreshGallery(prev => !prev);
          refetch(); // Manually trigger a refetch
        } else {
          console.error("Failed to update score");
        }
      } catch (error) {
        console.error("Error updating score:", error);
      }
    }
  };

  const handleRankingChange = async (newRanking) => {
    setSelectedIssues(newRanking);

    try {
      const promises = newRanking.map((issue, index) =>
        fetch(`${window.apiUrl}/userscores`, {
          method: 'PUT',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: userEmail,
            group_name: issue,
            rank: index + 1,
          }),
        })
      );

      const responses = await Promise.all(promises);
      responses.forEach(response => {
        if (!response.ok) {
          console.error("Failed to update ranking");
        }
      });
    } catch (error) {
      console.error("Error updating ranking:", error);
    }
  };

  const handleRefreshGallery = () => {
    setRefreshGallery(prevState => !prevState);
  };

  const filteredScores = scores.map(scoreData => ({
    ...scoreData,
    scores: scoreData.scores.filter(score => selectedIssues.includes(score.topic_name))
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="pickyourviews">
        <Banner
          title="Pick Your Views"
          description="Get side by side comparison for the issues you care about"
        />

        <div className="pickyourviews-main">
          {isLoading ? (
            <p className="loading-message">Loading, please wait...</p>
          ) : (
            <>
              <p className="intro">Below, you'll find a list of political topics. Select the issues that matter most to you. Don't worry if you're unsure about some topics — you can always modify your selections later in your profile settings.
              Use the slider to show where you fall on the political spectrum of each issue.</p>
              <p className="care">I care about...</p>
              <div className="issueselect">
                <MyIssues issues={issues} selectedIssues={selectedIssues} toggleIssue={toggleIssue} />
              </div>
              <div className="line"></div>
              <div className="scorecomparison">
                <ScoreComparison 
                  scores={filteredScores} 
                  onPositionChange={handlePositionChange} 
                  onRefreshGallery={handleRefreshGallery}
                  isUserScore={true}
                />
              </div>
              <div className="line"></div>

              <div className="similarreps">
                <RepGallery 
                  title="Similar Reps" 
                  description="See representatives that align with the views you've selected above." 
                  dataType="similar-me" 
                  item={userEmail} 
                  refreshGallery={refreshGallery} 
                  politicians={politicians}
                  error={error}
                  loading={loading}
                />
              </div>
              <div className="line"></div>
            </>
          )}
        </div>
      </div>
    </DndProvider>
  );
};
