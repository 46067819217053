import React from 'react';
import { Header } from '../../components/Header/Header';
import { HowItWorks } from '../../components/HowItWorks/HowItWorks';
import { WhyVotesee } from '../../components/WhyVotesee/WhyVotesee';
import { MoreInfo } from '../../components/MoreInfo/MoreInfo';
import { TopReps } from '../../components/TopReps/TopReps';
import './style.css'; // Create this CSS file to style the HomePage

export const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <HowItWorks />
      <WhyVotesee />
      <MoreInfo />
      <TopReps />
    </div>
  );
};

