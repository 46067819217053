import React, { useState, useRef, forwardRef } from 'react';
import { RepCard } from '../RepCard/RepCard';
import './style.css';
import iconLeft from './Icon-left.svg';
import iconRight from './Icon-right.svg';
import loadingIcon from './loading.svg';
import { usePoliticianData } from '../../hooks/usePoliticianData';
import { Link } from 'react-router-dom';

const cardWidth = 293; // Width of the RepCard
const cardGap = 90; // Gap between cards

export const RepGallery = forwardRef(({ 
    dataType = 'top', 
    item = 'null', 
    refreshGallery, 
    title = "Most Searched Reps", 
    description = "See which Congressional Representatives are searched most often by the VoteSee community.",
    showHeader = true,
    onCardSelect // Add this prop
}, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const galleryRef = useRef(null);
    const { politicians, error, loading } = usePoliticianData(dataType, item, refreshGallery);

    const handleScroll = (event) => {
        const scrollPosition = event.target.scrollLeft;
        const newIndex = Math.round(scrollPosition / (cardWidth + cardGap));
        setActiveIndex(newIndex);
    };

    const scrollGallery = (direction) => {
        if (galleryRef.current) {
            const newIndex = Math.max(0, Math.min(activeIndex + direction, politicians.length - 1));
            const targetScroll = newIndex * (cardWidth + cardGap);
            galleryRef.current.scrollTo({
                left: targetScroll,
                behavior: 'smooth'
            });
            setActiveIndex(newIndex);
        }
    };

    const handleDotClick = (index) => {
        if (galleryRef.current) {
            galleryRef.current.scrollTo({
                left: index * (cardWidth + cardGap),
                behavior: 'smooth'
            });
            setActiveIndex(index);
        }
    };

    if (loading) return <div className="loading-container"><img src={loadingIcon} alt="Loading..." /></div>;
    
    if (error || (dataType === 'similar-me' && politicians.length === 0)) {
        const errorMessage = dataType === 'similar-me' 
            ? "Please pick and score your views to find similar representatives!"
            : error;
        return <div className="error">{errorMessage}</div>;
    }

    return (
        <div className="gallery-holder">
            {showHeader && (
                <div className="title-wrap">
                    <div className="title">{title}</div>
                    <div className="text">{description}</div>
                </div>
            )}
            <div className="gallery-container">
                <div 
                    className="gallery" 
                    ref={galleryRef}
                    onScroll={handleScroll}
                >
                    {politicians.map((card, index) => (
                        <Link 
                            key={index} 
                            to={`/rep/${card.politician_id}`} 
                            className="rep-card-link"
                            onClick={() => onCardSelect && onCardSelect(card)}
                        >
                            <RepCard {...card} />
                        </Link>
                    ))}
                </div>
                <div className="slider-dots">
                    {politicians.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        ></span>
                    ))}
                </div>
                <div className="scroll-buttons">
                    <button 
                        className="scroll-button" 
                        onClick={() => scrollGallery(-1)}
                        disabled={activeIndex === 0}
                    >
                        <img src={iconLeft} alt="Scroll left" />
                    </button>
                    <button 
                        className="scroll-button" 
                        onClick={() => scrollGallery(1)}
                        disabled={activeIndex === politicians.length - 1}
                    >
                        <img src={iconRight} alt="Scroll right" />
                    </button>
                </div>
            </div>
        </div>
    );
});

