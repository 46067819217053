import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { Line } from 'react-chartjs-2';
import userScoreImage from './flag.webp';
import { Slider, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const BillCard = ({ bill }) => {
  const [showFullSummary, setShowFullSummary] = useState(false);

  if (bill.relation < 0.4) return null;

  // Approximation of the error function
  const erf = (x) => {
    const sign = x >= 0 ? 1 : -1;
    x = Math.abs(x);
    const a1 =  0.254829592;
    const a2 = -0.284496736;
    const a3 =  1.421413741;
    const a4 = -1.453152027;
    const a5 =  1.061405429;
    const p  =  0.3275911;
    const t = 1.0 / (1.0 + p * x);
    const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
    return sign * y;
  };

  // Calculate percentile correctly
  const zScore = (bill.score - bill.group_avg_score) / bill.group_std_dev;
  const percentile = 100- (0.5 * (1 + erf(zScore / Math.sqrt(2)))) * 100;

  const doughnutData = {
    labels: ['Yes', 'No', 'Not Voting'],
    datasets: [
      {
        data: [bill.yea_aye_count, bill.no_nay_count, bill.not_voting_count],
        backgroundColor: ['rgba(66, 133, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(158, 158, 158, 0.7)'],
        hoverBackgroundColor: ['rgba(66, 133, 244, 0.9)', 'rgba(244, 67, 54, 0.9)', 'rgba(158, 158, 158, 0.9)'],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 12,
          padding: 10,
          font: {
            size: 10
          }
        }
      },
      tooltip: {
        enabled: true
      }
    },
    cutout: '70%'
  };

  const toggleSummary = () => {
    setShowFullSummary(!showFullSummary);
  };

  return (
    <div className="bill-card">
      <div className="bill-header">
        <h4>{bill.bill_id}</h4>
        <span className="bill-result">{bill.result}</span>
      </div>
      <p className="topic-name">{bill.topic_name}</p>
      <div className="title-wrapper">
        <p className="title">{bill.popular_title || bill.official_title}</p>
      </div>
      <div className={`summary ${showFullSummary ? 'expanded' : ''}`}>
        <p>{bill.summary_text}</p>
      </div>
      {bill.summary_text.length > 300 && (
        <button className="show-more-btn" onClick={toggleSummary}>
          {showFullSummary ? 'Show less' : 'Show more'}
        </button>
      )}
      <div className="action-info">
        <p>Latest Action: {bill.action_text}</p>
        <p>Date: {new Date(bill.most_recent_action_date).toLocaleDateString()}</p>
      </div>
      <div className="stats-container">
        <div className="vote-chart">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
        <div className="percentile">
          <div className="percentile-bar">
            <div className="percentile-marker" style={{ left: `${percentile}%` }}></div>
          </div>
          <span className="liberal">Liberal</span>
          <span className="conservative">Conservative</span>
        </div>
      </div>
    </div>
  );
};

export const IssueBar = ({ head, issues, onPositionChange, onRefreshGallery, isUserScore = false, party }) => {
  const [expanded, setExpanded] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [subTopics, setSubTopics] = useState([]);
  const [importantBills, setImportantBills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYearPair, setSelectedYearPair] = useState(null);
  const [yearPairs, setYearPairs] = useState([]);
  const [lastAvailableYearPair, setLastAvailableYearPair] = useState(null);
  const [partyAverages, setPartyAverages] = useState({});
  const [hasGrayedOutRep, setHasGrayedOutRep] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [currentPosition, setCurrentPosition] = useState({});
  const [callouts, setCallouts] = useState([]);
  const [averagePositions, setAveragePositions] = useState({});
  const [processedData, setProcessedData] = useState({});

  const sliderRef = useRef(null);
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (!isUserScore) {
      const calculateAveragePositions = () => {
        const averages = {};
        issues.forEach(issue => {
          if (!averages[issue.politician_id]) {
            averages[issue.politician_id] = {
              totalScore: 0,
              count: 0,
            };
          }
          if (issue.bill_count >= 1) {
            averages[issue.politician_id].totalScore += issue.average_score;
            averages[issue.politician_id].count += 1;
          }
        });

        Object.keys(averages).forEach(id => {
          if (averages[id].count > 0) {
            averages[id] = averages[id].totalScore / averages[id].count;
          } else {
            averages[id] = null;
          }
        });

        setAveragePositions(averages);
      };

      calculateAveragePositions();
    }
  }, [issues, isUserScore]);

  useEffect(() => {
    if (!isUserScore && issues.length > 0) {
      processIssueData(issues);
    }
  }, [issues, isUserScore]);

  const processIssueData = (issuesData) => {
    const politicianData = {};
    const yearGroups = generateYearGroups();

    const MIN_BILL_COUNT = 1; // Set a minimum threshold for bill count

    // Group data by politician
    issuesData.forEach(issue => {
      if (!politicianData[issue.politician_id]) {
        politicianData[issue.politician_id] = [];
      }
      politicianData[issue.politician_id].push(issue);
    });

    // Process data for each politician
    Object.keys(politicianData).forEach(politicianId => {
      const politician = politicianData[politicianId];
      const processedYearGroups = {};

      yearGroups.forEach(yearGroup => {
        const [endYear, startYear] = yearGroup;
        const relevantIssues = politician.filter(issue => issue.year >= startYear && issue.year <= endYear);

        if (relevantIssues.length > 0) {
          const validIssues = relevantIssues.filter(issue => issue.bill_count >= MIN_BILL_COUNT);
          if (validIssues.length > 0) {
            const totalBillCount = validIssues.reduce((sum, issue) => sum + issue.bill_count, 0);
            const weightedScore = validIssues.reduce((sum, issue) => sum + issue.average_score * issue.bill_count, 0);
            const averageScore = weightedScore / totalBillCount;
            const averageBillCount = totalBillCount / validIssues.length;

            processedYearGroups[yearGroup.join('-')] = {
              averageScore,
              averageBillCount,
              isValid: true,
              modifiedScore: averageScore,
            };
          } else {
            processedYearGroups[yearGroup.join('-')] = {
              averageScore: null,
              averageBillCount: relevantIssues.reduce((sum, issue) => sum + issue.bill_count, 0),
              isValid: false,
              modifiedScore: null,
            };
          }
        } else {
          processedYearGroups[yearGroup.join('-')] = {
            averageScore: null,
            averageBillCount: 0,
            isValid: false,
            modifiedScore: null,
          };
        }
      });

      // Assign modified scores for invalid years
      Object.keys(processedYearGroups).forEach(yearGroupKey => {
        const yearGroup = processedYearGroups[yearGroupKey];
        if (!yearGroup.isValid) {
          const nearestValidScore = findNearestValidScore(processedYearGroups, yearGroupKey);
          yearGroup.modifiedScore = nearestValidScore;
          yearGroup.status = yearGroup.averageBillCount > 0 ? 'insufficient_votes' : 'not_in_office';
        }
      });

      // Check if there are any valid scores
      const hasValidScores = Object.values(processedYearGroups).some(yearGroup => yearGroup.isValid);

      // If no valid scores, set all scores to 50
      if (!hasValidScores) {
        const totalBillCount = Object.values(processedYearGroups).reduce((sum, yearGroup) => sum + yearGroup.averageBillCount, 0);
        const status = totalBillCount > 0 ? 'insufficient_votes' : 'no_bills';
        
        Object.keys(processedYearGroups).forEach(yearGroupKey => {
          processedYearGroups[yearGroupKey].modifiedScore = 50;
          processedYearGroups[yearGroupKey].status = status;
        });
      }

      politicianData[politicianId] = processedYearGroups;
    });

    setProcessedData(politicianData);
  };

  const generateYearGroups = () => {
    const currentYear = new Date().getFullYear();
    const yearGroups = [];
    for (let year = currentYear; year > 2013; year -= 2) {
      yearGroups.push([year, year - 1]);
    }
    return yearGroups;
  };

  const findNearestValidScore = (yearGroups, currentYearGroup) => {
    const years = Object.keys(yearGroups);
    const currentIndex = years.indexOf(currentYearGroup);
    let leftIndex = currentIndex - 1;
    let rightIndex = currentIndex + 1;

    while (leftIndex >= 0 || rightIndex < years.length) {
      if (leftIndex >= 0 && yearGroups[years[leftIndex]].isValid) {
        return yearGroups[years[leftIndex]].averageScore;
      }
      if (rightIndex < years.length && yearGroups[years[rightIndex]].isValid) {
        return yearGroups[years[rightIndex]].averageScore;
      }
      leftIndex--;
      rightIndex++;
    }
    return null;
  };

  const getCurrentIssues = useCallback((yearPair) => {
    if (isUserScore) return issues;
    if (!yearPair) return [];
    
    const yearGroupKey = `${yearPair[0]}-${yearPair[1]}`;
    
    return Object.keys(processedData).map(politicianId => {
      const politicianYearData = processedData[politicianId][yearGroupKey];
      if (!politicianYearData) return null;

      const baseIssue = issues.find(issue => issue.politician_id === politicianId);
      if (!baseIssue) return null;

      return {
        ...baseIssue,
        average_score: politicianYearData.modifiedScore,
        position: politicianYearData.modifiedScore,
        isGrayedOut: !politicianYearData.isValid,
        isOutOfOffice: politicianYearData.status === 'not_in_office',
        insufficientVotes: politicianYearData.status === 'insufficient_votes',
        year: yearGroupKey,
      };
    }).filter(Boolean);
  }, [processedData, issues, isUserScore]);

  useEffect(() => {
    if (!isUserScore) {
      const generateYearPairs = () => {
        const pairs = [];
        for (let year = currentYear; year > 2013; year -= 2) {
          pairs.push([year, year - 1]);
        }
        return pairs;
      };

      const allYearPairs = generateYearPairs();
      setYearPairs(allYearPairs);

      if (issues.length > 0) {
        const availableYears = issues.map(issue => issue.year);
        const defaultPair = allYearPairs.find(pair => availableYears.includes(pair[0]) || availableYears.includes(pair[1])) || allYearPairs[0];
        setSelectedYearPair(defaultPair);
        setLastAvailableYearPair(defaultPair);
      }
    }
  }, [issues, currentYear, isUserScore]);

  useEffect(() => {
    if (selectedYearPair && activeSection === 'stats' && !isUserScore) {
      fetchGraphData(selectedYearPair);
      fetchAverageScores(selectedYearPair);
    }
  }, [selectedYearPair, activeSection, isUserScore]);

  useEffect(() => {
    if (!isUserScore) {
      const currentIssues = getCurrentIssues(selectedYearPair);
      setHasGrayedOutRep(currentIssues.length === 0);
    }
  }, [selectedYearPair, isUserScore, getCurrentIssues]);

  const updateCallouts = useCallback((yearPair, currentIssues) => {
    const newCallouts = new Set();

    currentIssues.forEach(issue => {
      if (issue.display_name !== 'You') {
        if (issue.status === 'no_bills') {
          newCallouts.add(`*${issue.display_name} has not sponsored or voted on any bills in this area. Showing a neutral score of 50.`);
        } else if (issue.status === 'insufficient_votes') {
          newCallouts.add(`*${issue.display_name} has not sponsored/voted on enough bills in this area to give an exact score. Showing a neutral score of 50.`);
        } else if (issue.isOutOfOffice) {
          newCallouts.add(`*${issue.display_name} was not in office during these years. Showing nearest available data.`);
        } else if (issue.insufficientVotes) {
          newCallouts.add(`*${issue.display_name} has not sponsored/voted on enough bills in this area to give an exact score.`);
        }
      }
    });

    setCallouts(Array.from(newCallouts));
  }, []);

  useEffect(() => {
    if (selectedYearPair && !isUserScore) {
      const currentIssues = getCurrentIssues(selectedYearPair);
      updateCallouts(selectedYearPair, currentIssues);
    }
  }, [selectedYearPair, isUserScore, getCurrentIssues, updateCallouts]);

  const handleButtonClick = (section) => {
    if (activeSection === section) {
      setActiveSection(null);
      setExpanded(false);
    } else {
      setActiveSection(section);
      setExpanded(true);
      if (section === 'stats' && selectedYearPair && !isUserScore) {
        fetchGraphData(selectedYearPair);
        fetchAverageScores(selectedYearPair);
      } else if (section === 'subtopics') {
        fetchSubTopics();
      } else if (section === 'bills') {
        fetchImportantBills();
      }
    }
  };

  const handleYearPairChange = (event, newValue) => {
    if (!isUserScore) {
      const newYearPair = yearPairs[newValue];
      setSelectedYearPair(newYearPair);
      const currentIssues = getCurrentIssues(newYearPair);
      if (currentIssues.some(issue => !issue.isGrayedOut)) {
        setLastAvailableYearPair(newYearPair);
      }
      updateCallouts(newYearPair, currentIssues);
    }
  };

  const fetchGraphData = async (yearPair) => {
    if (!yearPair || isUserScore) return;
    try {
      const responses = await Promise.all(yearPair.map(year => 
        axios.get(`${window.apiUrl}/userscoresummary?group_name=${head}&year=${year}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        })
      ));
      
      const data = responses.flatMap(response => response.data);
      if (!Array.isArray(data) || data.length === 0) {
        setGraphData(null);
        return;
      }
  
      const buckets = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  
      const calculatePercentages = (counts) => {
        const total = counts.reduce((acc, count) => acc + count, 0);
        return counts.map(count => (count / total) * 100);
      };
  
      const getCounts = (party) => {
        return buckets.map(bucket => {
          const entries = data.filter(entry => 
            entry.party === party && 
            entry.bucket === bucket && 
            (entry.year === yearPair[0] || entry.year === yearPair[1])
          );
          return entries.reduce((sum, entry) => sum + entry.count, 0);
        });
      };
  
      const republicanCounts = getCounts('Republican');
      const democratCounts = getCounts('Democrat');
  
      const republicanPercentages = calculatePercentages(republicanCounts);
      const democratPercentages = calculatePercentages(democratCounts);
  
      setGraphData({
        labels: buckets.reverse(),
        datasets: [
          {
            label: 'Republican Scores',
            data: republicanPercentages,
            fill: true,
            backgroundColor: 'rgba(240, 106, 106, 0.4)',
            borderColor: 'rgba(240, 106, 106, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
          {
            label: 'Democrat Scores',
            data: democratPercentages,
            fill: true,
            backgroundColor: 'rgba(66, 133, 244, 0.4)',
            borderColor: 'rgba(66, 133, 244, 1)',
            tension: 0.4,
            pointRadius: 0,
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching graph data:', error);
      setGraphData(null);
    }
  };

  const fetchAverageScores = async (yearPair) => {
    if (!yearPair || isUserScore) return;
    try {
      const responses = await Promise.all(yearPair.map(year => 
        axios.get(`${window.apiUrl}/partyscoresummary?group_name=${head}&year=${year}`, {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY_1,
            'Content-Type': 'application/json'
          }
        })
      ));
      
      const data = responses.flatMap(response => response.data);

      const averages = {};
      ['Democrat', 'Republican'].forEach(party => {
        const partyData = data.filter(item => item.party === party && (item.year === yearPair[0] || item.year === yearPair[1]));
        if (partyData.length > 0) {
          const sum = partyData.reduce((acc, item) => {
            return acc + (parseFloat(item.average_score) || 0)
          }, 0);
          averages[party] = (sum / partyData.length).toFixed(2);
        } else {
          averages[party] = 'N/A';
        }
      });

      setPartyAverages(averages);
    } catch (error) {
      console.error('Error fetching average scores:', error);
    }
  };

  const fetchSubTopics = async () => {
    try {
      const response = await axios.get(`${window.apiUrl}/grouptopics?group_name=${head}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      setSubTopics(response.data);
    } catch (error) {
    }
  };

  const fetchImportantBills = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${window.apiUrl}/groupbillsvotes?group_name=${head}`, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_1,
          'Content-Type': 'application/json'
        }
      });
      const filteredBills = response.data.filter(bill => bill.relation >= 0.4);
      setImportantBills(filteredBills.slice(0, 5));
    } catch (error) {
      console.error('Error fetching important bills:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleThumbMouseDown = (event, index) => {
    if (!isUserScore) return;

    setDraggingIndex(index);

    const thumb = event.target;
    const track = thumb.parentElement;
    const trackRect = track.getBoundingClientRect();
    const initialClientX = event.clientX || event.touches[0].clientX;
    const initialLeft = thumb.getBoundingClientRect().left - trackRect.left;

    let isFirstMove = true;
    let latestPosition = currentPosition[index] !== undefined ? currentPosition[index] : issues[index]?.position || 0;

    const onMove = (moveEvent) => {
      const clientX = moveEvent.clientX || moveEvent.touches[0].clientX;

      if (isFirstMove) {
        isFirstMove = false;
        return;
      }

      const clientDeltaX = clientX - initialClientX;
      const newLeft = initialLeft + clientDeltaX;
      const newPosition = Math.max(0, Math.min(trackRect.width, newLeft));
      latestPosition = (newPosition / trackRect.width) * 100;

      const ItemWidth = track.parentElement.getBoundingClientRect().width;
      const x0 = thumb.parentElement.parentElement.getBoundingClientRect().x;
      let currentPosition = clientX;
      latestPosition = 100 - (currentPosition - x0) / ItemWidth * 100;

      latestPosition = Math.max(0, latestPosition);
      latestPosition = Math.min(100, latestPosition);

      setCurrentPosition((prevState) => ({
        ...prevState,
        [index]: latestPosition,
      }));
    };

    const onEnd = () => {
      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('mouseup', onEnd);
      document.removeEventListener('touchmove', onMove);
      document.removeEventListener('touchend', onEnd);
      setDraggingIndex(null);
      onPositionChange(issues[index]?.topic, index, latestPosition);
      if (onRefreshGallery) {
        onRefreshGallery();
      }
    };

    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', onEnd);

    event.preventDefault();
  };

  const renderVerticalLine = (xPosition, color, label) => {
    if (xPosition === 'N/A' || isNaN(xPosition)) {
      return null;
    }
    const position = parseFloat(xPosition);
    return (
      <div
        key={`${label}-${position}`}
        className="vertical-line"
        style={{ left: `${100 - position}%`, borderColor: color }}
      >
        <div className="vertical-line-label" style={{ backgroundColor: color }}>
          {label}
        </div>
      </div>
    );
  };

  const sliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#4285f4',
            height: 8,
          },
          thumb: {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
          },
          valueLabel: {
            left: 'calc(-50% + 4px)',
          },
        },
      },
    },
  });

  const averageScore = useMemo(() => {
    const currentIssues = getCurrentIssues(lastAvailableYearPair);
    if (currentIssues.length === 0) return 'N/A';
    const sum = currentIssues.reduce((acc, issue) => acc + (issue.average_score || issue.score || 0), 0);
    return (sum / currentIssues.length).toFixed(2);
  }, [getCurrentIssues, lastAvailableYearPair]);

  const getBorderColor = (issueParty) => {
    const partyToUse = issueParty || party;
    switch (partyToUse) {
      case 'Republican':
        return 'rgba(123, 6, 30, 0.66)';
      case 'Democrat':
        return '#458BEA';
      case 'Independent':
        return '#808080';
      default:
        return '#000000';
    }
  };

  const getGlowColor = (party) => {
    switch (party) {
      case 'Republican':
        return 'rgba(240, 106, 106, 0.66)';
      case 'Democrat':
        return 'rgba(66, 133, 244, 0.66)';
      case 'Independent':
        return 'rgba(128, 128, 128, 0.5)';
      default:
        return 'rgba(0, 0, 0, 0.5)';
    }
  };

  return (
    <div className={`parent-container ${expanded ? 'expanded' : ''}`}>
      <div className="title-container">
        <h2 className="issue-title">{head}</h2>
        <div className="button-group">
          <button className={`minimalist-button stats ${activeSection === 'stats' ? 'active' : ''}`} onClick={() => handleButtonClick('stats')}>
            Stats
            <span className={`button-caret ${activeSection === 'stats' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button definition ${activeSection === 'definition' ? 'active' : ''}`} onClick={() => handleButtonClick('definition')}>
            Definition
            <span className={`button-caret ${activeSection === 'definition' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button bills ${activeSection === 'bills' ? 'active' : ''}`} onClick={() => handleButtonClick('bills')}>
            Recent Bills
            <span className={`button-caret ${activeSection === 'bills' ? 'expanded' : ''}`}></span>
          </button>
          <button className={`minimalist-button subtopics ${activeSection === 'subtopics' ? 'active' : ''}`} onClick={() => handleButtonClick('subtopics')}>
            Sub-topics
            <span className={`button-caret ${activeSection === 'subtopics' ? 'expanded' : ''}`}></span>
          </button>
        </div>
      </div>
      <div className="issue-bar-container">
        <div className="issue-bar">
          <div className="issue-track">
            {getCurrentIssues(selectedYearPair).map((item, index) => (
              <div
                key={index}
                className={`issue-thumb ${isUserScore && draggingIndex === index ? 'dragging' : ''} ${item.isGrayedOut ? 'grayed-out' : ''} ${item.isOutOfOffice ? 'out-of-office' : ''}`}
                style={{
                  left: `${100 - (currentPosition[index] !== undefined ? currentPosition[index] : (item.position || item.average_score || 0))}%`,
                  borderColor: getBorderColor(item.party),
                  boxShadow: `0 0 10px ${getGlowColor(item.party)}`,
                  opacity: item.isGrayedOut || item.isOutOfOffice ? 0.5 : 1,
                  transition: item.display_name === 'You' ? 'none' : 'left 0.3s ease-in-out', // Disable animation for "You"
                }}
                onMouseDown={(event) => isUserScore && handleThumbMouseDown(event, index)}
                onTouchStart={(event) => isUserScore && handleThumbMouseDown(event, index)}
              >
                <img 
                  src={isUserScore ? userScoreImage : (item.imageUrl || userScoreImage)} 
                  alt={`${item.display_name || 'User'} Score Thumb`} 
                />
                <span className="tooltip">
                  {item.display_name || 'User'}: {typeof item.position === 'number' ? item.position.toFixed(2) : 'N/A'}
                  {item.isGrayedOut && ' (Insufficient data)'}
                  {item.isOutOfOffice && ` (Out of office, showing ${item.year})*`}
                </span>
              </div>
            ))}
            {activeSection === 'stats' && expanded && !isUserScore && (
              <>
                {renderVerticalLine(partyAverages['Democrat'], '#4285f4', 'D')}
                {renderVerticalLine(partyAverages['Republican'], '#f06a6a', 'R')}
              </>
            )}
          </div>
          {activeSection === 'stats' && expanded && (
            <div className="expanded-section">
              {isUserScore ? (
                <div className="no-data-message">Histogram not available for user scores.</div>
              ) : graphData && (
                <Line
                  data={graphData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: { display: false, reverse: true },
                      y: { display: false, reverse: true },
                    },
                    plugins: { legend: { display: false } },
                    elements: { line: { tension: 0.4 } },
                  }}
                  style={{ width: '100%', height: '150px' }}
                  className="issue-graph"
                />
              )}
              <div className="legend-label">
                Average of <span className="republicans">republicans</span> and <span className="democrats">democrats</span>
              </div>
              {!isUserScore && (
                <div className="year-slider-container" ref={sliderRef}>
                  {hasGrayedOutRep && (
                    <div className="gray-disclaimer">One or more representatives were not in office during this time</div>
                  )}
                  {
                    !isUserScore && (
                      <div className="year-slider-wrapper">
                        <Typography id="year-range-slider" className="year-label">
                          Selected Years: {selectedYearPair ? `${selectedYearPair[1]}-${selectedYearPair[0]}` : ''}
                        </Typography>
                        <ThemeProvider theme={sliderTheme}>
                          <Slider
                            className="year-slider"
                            value={yearPairs.findIndex(pair => pair[0] === selectedYearPair[0] && pair[1] === selectedYearPair[1])}
                            min={0}
                            max={yearPairs.length - 1}
                            step={1}
                            onChange={handleYearPairChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="year-slider"
                            valueLabelFormat={(index) => {
                              const pair = yearPairs[index];
                              return pair ? `${pair[1]}-${pair[0]}` : '';
                            }}
                          />
                        </ThemeProvider>
                      </div>
                    )
                  }
                  <div className="callouts">
                    {callouts.map((callout, index) => (
                      <p key={index} className="callout">{callout}</p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {activeSection === 'definition' && expanded && (
            <div className="expanded-section definition-section">
              <h3>Definition</h3>
              <p>{getCurrentIssues(selectedYearPair)[0]?.description || 'No description available'}</p>
              <div className="definition-labels">
                <div className="definition-label">
                  <h4>{getCurrentIssues(selectedYearPair)[0]?.p2 || ''}</h4>
                  <p>{getCurrentIssues(selectedYearPair)[0]?.p2_def || ''}</p>
                </div>
                <div className="definition-label">
                  <h4>{getCurrentIssues(selectedYearPair)[0]?.p1 || ''}</h4>
                  <p>{getCurrentIssues(selectedYearPair)[0]?.p1_def || ''}</p>
                </div>
              </div>
            </div>
          )}
          {activeSection === 'subtopics' && expanded && (
            <div className="expanded-section subtopics-section">
              <h3>Sub-topics</h3>
              <div className="subtopics-bubbles">
                {subTopics.map((topic, index) => (
                  <div key={index} className="subtopic-bubble">
                    {topic.topic_name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeSection === 'bills' && expanded && (
            <div className="expanded-section bills-section">
              <h3>Recent Bills</h3>
              {isLoading ? (
                <div className="loading-spinner"></div>
              ) : (
                <div className="bills-list">
                  {importantBills.map((bill, index) => (
                    <BillCard key={index} bill={bill} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="issue-labels">
        <span title={getCurrentIssues(selectedYearPair)[0]?.p1_def || ''}>{getCurrentIssues(selectedYearPair)[0]?.p1 || ''}</span>
        <span title={getCurrentIssues(selectedYearPair)[0]?.p2_def || ''}>{getCurrentIssues(selectedYearPair)[0]?.p2 || ''}</span>
      </div>
    </div>
  );
};

const getBorderColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(123, 6, 30, 0.66)';
    case 'Democrat':
      return '#458BEA';
    case 'Independent':
      return '#808080';
    default:
      return '#000000';
  }
};

const getGlowColor = (party) => {
  switch (party) {
    case 'Republican':
      return 'rgba(240, 106, 106, 0.66)';
    case 'Democrat':
      return 'rgba(66, 133, 244, 0.66)';
    case 'Independent':
      return 'rgba(128, 128, 128, 0.5)';
    default:
      return 'rgba(0, 0, 0, 0.5)';
  }
};